import React from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { QRCodeSVG } from 'qrcode.react';
import PropTypes from 'prop-types';
import VerifyOtpForm from 'components/verifyotpform';
import { createOtpSecret, validateToken } from 'otputils';
import spinner from '../../static/spinner.gif';

import Page from '../../components/page';
import { PhoneCheckmarkOn } from 'components/icons';
import CancelPopup from "../../components/cancelpopup";
import Button, { ButtonGroup } from '../../components/button';
import ButtonLink from 'components/buttonlink';
import { SubmissionError } from "redux-form";


import {
  chooseUserPath, loggedOutPath,
} from '../../routeConstants';
import { redirectTo } from 'actions/utils';
import { setOtpSecret } from 'actions';
import Warning from 'components/warning';

import pofhConfig from '../../pofhConfig';
const { otpEnabled } = pofhConfig.appSettings;

class OtpOn extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      otpSecret: createOtpSecret(),
      notvalidtoken: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleError = this.handleError.bind(this);
    this.cancelotp = this.cancelotp.bind(this);
    this.hideCancelPopup = this.hideCancelPopup.bind(this);
    this.showCancelPopup = this.showCancelPopup.bind(this);
  }

  showCancelPopup() {
    this.setState({ showCancelPopup: true });
  }

  hideCancelPopup() {
    this.setState({ showCancelPopup: false });
  }

  // Takes the user back to the oidc menu
  cancelotp() {
    redirectTo(chooseUserPath);
  }

  // Proceed to next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1
    });
  }

  handleSubmit(payload) {
    if (validateToken(payload.usercode, this.state.otpSecret)) {
      this.setState({ notvalidtoken: false });
      this.props.setOtpSecret({ otp_secret: this.state.otpSecret })
        .then(() => {
          this.nextStep()
        }, this.handleError);
    } else {
      this.setState({ notvalidtoken: true });
    }
  }


  handleError(errorAction) {
    const _error = {};
    if (errorAction.error.type === 'unauthorized') {
      redirectTo(loggedOutPath, {
        logoutHeader: 'general.logged_out',
        logoutMessageTag: 'general.warning_oidc_session_expired',
      });
    } else if (errorAction.error.type === 'missing-session') {
      _error._error = <Translate value="serverErrors.missingSession" />;
    } else if (errorAction.error.type === 'rate-limit-error') {
      _error._error = <Translate value="serverErrors.rateLimit" />;
    } else {
      _error._error = <Translate value="serverErrors.serverDown" />;
    }
    throw new SubmissionError(_error);
  }

  render() {
    const { step, otpSecret } = this.state;
    const otpUrl = `otpauth://totp/Local 2FA?secret=${otpSecret}&issuer=uio.no`;

    switch (step) {
      case 1:
        return otpEnabled ? (
          <Page
            header={I18n.t('general.enable_otp_text.header_1')}
            infoText={I18n.t('general.enable_otp_text.page_1_info')}
          >
            <br>
            </br>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }}>
              <QRCodeSVG value={otpUrl}
              />
              <br></br>
              <p>
                <Translate value="general.enable_otp_text.page_1_info_alt" />
              </p>
              <p>
                <Translate value={otpSecret} />
              </p>
            </div>
            <br></br>
            <p>
              <Translate value="general.enable_otp_text.page_1_info_end" />
            </p>
            <ButtonGroup>
              <Button onClick={this.showCancelPopup}
                type='button'
                text={I18n.t('general.cancel')}
                secondary
              />
              <Button onClick={this.nextStep} text={I18n.t('general.next')} />
            </ButtonGroup>
            {this.state.showCancelPopup && (
              <CancelPopup
                noAction={this.hideCancelPopup}
                yesAction={this.cancelotp}
              />
            )}
          </Page >
        ) : (
          <Page header={I18n.t('general.enable_otp_text.header_1')}>
            <img className="spinner" src={spinner} alt="spinner" />
          </Page>
        )
      case 2:
        return (
          <Page
            header={I18n.t('general.enable_otp_text.header_2')}
            infoText={I18n.t('general.enable_otp_text.page_2_info')}
          >
            <VerifyOtpForm
              onSubmit={this.handleSubmit}
              cancel={this.showCancelPopup}
            />
            {this.state.notvalidtoken &&
              <Warning text={I18n.t("general.enable_otp_text.page_2_not_valid")} color="red" />
            }
            {this.state.showCancelPopup && (
              <CancelPopup
                noAction={this.hideCancelPopup}
                yesAction={this.cancelotp}
              />
            )}
          </Page>
        )
      case 3:
        return (
          <Page
            header={I18n.t('general.enable_otp_text.header_3')}
            infoText={I18n.t('general.enable_otp_text.page_3_info')}
          >
            <br>
            </br>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <PhoneCheckmarkOn />
            </div>
            <div>
              <br>
              </br>
              <Warning
                text={I18n.t('general.enable_otp_text.page_3_delay')}
                color="yellow"
              />
            </div>
            <ButtonLink
              href={chooseUserPath}
              text={I18n.t('general.done')}
            />
          </Page>
        )
    }
  }
}

OtpOn.propTypes = {
  cancel: PropTypes.func,
}

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
  };
}

export default connect(mapStateToProps, {
  setOtpSecret,
})(OtpOn);
