import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { Translate, I18n } from 'react-redux-i18n';

import Page from '../../components/page';
import LoginForm from 'components/loginform';
import { redirectTo } from '../../actions/utils';
import {
  fetchUserInfo,
  initiateLogin,
  storeSessionType,
  storeUserInfo,
} from '../../actions';

import {
  changePasswordPath,
  frontpagePath,
} from '../../routeConstants';

import pofhConfig from '../../pofhConfig';
const {
  stripLastAtSignAndRestFromUsername,
} = pofhConfig.appSettings;

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      showPassword: false,
      username : null,
    };
  }
  onSubmit(payload) {
    if (stripLastAtSignAndRestFromUsername) {
      payload.username = payload.username.replace(/@[^@]*$/, "");
    }
    this.setState({username: payload.username})
    return this.props.initiateLogin(payload).then(
      () => {
        this.props.storeSessionType("pofh");
        this.props.fetchUserInfo().then(
          (success) => {
            this.props.storeUserInfo(
              success.response.identifier,
              success.response.idm_source
            );
          },
          () => {
            this.props.storeUserInfo(null, null);
          }
        );
        redirectTo(changePasswordPath);
      },
      (errorAction) => {
        const _error = {};
        if (errorAction.error.type === "invalid-creds") {
          _error._error = I18n.t("serverErrors.invalidUsernameOrPassword");
        } else if (errorAction.error.type === "missing-session") {
          _error._error = I18n.t("serverErrors.missingSession");
        } else if (errorAction.error.type === "rate-limit-error") {
          _error._error = I18n.t("serverErrors.rateLimit");
        } else {
          _error._error = I18n.t("serverErrors.serverDown");
        }
        this.props.storeSessionType(null);
        throw new SubmissionError(_error);
      }
    );
  }
  render() {
    return (
      <Page header={<Translate value="general.log_in" />}
        goBackRoute={frontpagePath}
      >
        <LoginForm onSubmit={this.onSubmit} />
      </Page>
    );
  }
}


Login.propTypes = {
  fetchUserInfo: PropTypes.func.isRequired,
  initiateLogin: PropTypes.func.isRequired,
  locale: PropTypes.string,
  storeSessionType: PropTypes.func.isRequired,
  storeUserInfo: PropTypes.func.isRequired,

};

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
  };
}

export default connect(mapStateToProps, {
  fetchUserInfo,
  initiateLogin,
  storeSessionType,
  storeUserInfo,
})(Login);
