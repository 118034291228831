import React from 'react';
import classNames from 'classnames';

const FieldGroup = (props) => {
  const fieldGroupClassNames = classNames({
    'field-group': true,
    'field-group--inline': props.inline
  });
  return (
    <div className={ fieldGroupClassNames }>
      { props.children }
    </div>
  )
};

export default FieldGroup;
