import { SAVE_USER_INFO } from "../actionConstants";
import { logoutPath } from "../routeConstants";

const displayUserInfoBar = (idmSource, userId) => {
  if (idmSource === "id_porten") {
    document.getElementById("user-bar").style.display = "block";
    document.getElementById("oidc-bar").style.display = "flex";
    document.getElementById("logout-link").href = logoutPath;
  } else {
    document.getElementById("oidc-bar").style.display = "none";
    document.getElementById("user-bar").style.display = "block";
    document.getElementById("logout-link").href = logoutPath;
  }
  document.getElementById("user-id").innerHTML = userId;
};

const hideUserInfoBar = () => {
  document.getElementById("user-bar").style.display = "none";
  document.getElementById("oidc-bar").style.display = "none";
  document.getElementById("user-id").innerHTML = "";
  document.getElementById("logout-link").href = "";
};

export const userInfoBarMiddleware = (store) => (next) => (action) => {
  const state = store.getState();
  if (!state.session.sessionType) {
    // We are not logged in, and we should not display the userInfoBar
    hideUserInfoBar();
  } else if (state.session.userId && state.session.idmSource) {
    if (state.session.idmSource === "sms_token") {
      // We got the user info for a sms_token.
      // We should never end ut here.
      hideUserInfoBar();
    } else {
      // If userId and idmSource is present in state, we should display the userInfoBar.
      // This will run on every redux action. This is a bit excessive, but the runtime
      // does not seem to be effected.
      // This middleware can be removed if the userBar is moved into React.
      displayUserInfoBar(state.session.idmSource, state.session.userId);
    }
  } else if (action.type === SAVE_USER_INFO) {
    if (action.idmSource === "sms_token") {
      // Hide the display if we get a sms_token
      hideUserInfoBar();
    } else {
      // Display on save to display the userInfoBar faster
      displayUserInfoBar(action.idmSource, action.userId);
    }
  }
  return next(action);
};
