import {
  REQUEST_USERNAMES,
  SAVE_ID_NUMBER,
  SAVE_USERNAME,
  SAVE_SESSION_TYPE,
  LOGOUT,
  CLEAR_LOGOUT_MESSAGE,
  SET_LOGOUT_MESSAGE,
  REQUEST_LOGIN,
  REQUEST_SMS_TOKEN,
  SUCCESS,
  SAVE_USER_INFO,
} from "../actionConstants";

export const initialState = {
  logoutHeader: null,
  logoutMessageTag: null,
  identifier: null,
  username: null,
  userNames: null,
  userId: null,
  idmSource: null,
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case REQUEST_USERNAMES:
      switch (action.status) {
        case SUCCESS:
          return Object.assign({}, state, {
            userNames: action.response.usernames,
          });
        default:
          return state;
      }

    case SAVE_ID_NUMBER:
      return Object.assign({}, state, { identifier: action.identifier });

    case SAVE_USERNAME:
      return Object.assign({}, state, { username: action.username });

    case SAVE_SESSION_TYPE:
      return Object.assign({}, state, { sessionType: action.sessionType });

    case REQUEST_LOGIN:
      return Object.assign({}, state, { sessionType: action.sessionType });

    case REQUEST_SMS_TOKEN:
      return Object.assign({}, state, { sessionType: action.sessionType });

    case SAVE_USER_INFO:
      return Object.assign({}, state, {
        userId: action.userId,
        idmSource: action.idmSource,
      });

    case LOGOUT:
      return Object.assign({}, initialState, {
        userId: null,
        idmSource: null,
        sessionType: null,
      });

    case SET_LOGOUT_MESSAGE:
      return Object.assign({}, initialState, {
        logoutHeader: action.logoutHeader,
        logoutMessageTag: action.logoutMessageTag,
        logoutMessageTagVars: action.logoutMessageTagVars,
      });

    case CLEAR_LOGOUT_MESSAGE:
      return Object.assign({}, state, {
        logoutHeader: null,
        logoutMessageTag: null,
      });

    default:
      return state;
  }
}
