import React from 'react';
import classNames from 'classnames';

const UnorderedMessageList = ({children, className}) => (
  <ul className={className}>
    {children}
  </ul>
);

const OrderedMessageList = ({children, className}) => (
  <ol className={className} type="1">
    {children}
  </ol>
);

const Messages = ({messages, alignLeft, numbered}) => {
  const listClassNames = classNames({
    'message-list': true,
    'message-list--align-left': alignLeft
  });
  const itemClassNames = classNames({
    'message-list__item': true,
    'message-list__item--ordered': numbered,
    'message-list__item--unordered': !numbered
  });
  let MessageList = null;
  if (numbered)
    MessageList = OrderedMessageList;
  else
    MessageList = UnorderedMessageList;
  return (
    <MessageList className={listClassNames}>
      {messages.map((message, index) => (
        <li key={index} className={itemClassNames}
            dangerouslySetInnerHTML={{ __html: message }}>
        </li>
      ))}
    </MessageList>
  );
};

export default Messages;