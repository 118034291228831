import React from 'react';
import { connect } from 'react-redux';
import Page from '../../components/page';
import Button from '../../components/button';
import { redirectTo } from '../../actions/utils';
import { chooseUserPath } from '../../routeConstants';
import { I18n } from 'react-redux-i18n';
import spinner from '../../static/spinner.gif';

import pofhConfig from '../../pofhConfig';
const { otpEnabled } = pofhConfig.appSettings;

class OtpOffPage extends React.Component {
  toProfile() {
    redirectTo(chooseUserPath);
  }
  render() {
    return otpEnabled ? (
      <Page header={I18n.t('general.otp_off_text')}>
        <Button
          type="button"
          onClick={this.toProfile.bind(this)}
          text={I18n.t('general.finish')}
          wide
        />
      </Page>
    ) : (
      <img className="spinner" src={spinner} alt="spinner" />
    );
  }
}

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
  };
}

export default connect(mapStateToProps)(OtpOffPage);
