// Id-tags for elements that are part of the instance's header/footer-theme,
// that should be updated when the user changes locale from within the app.
//
// Values for tags that end with '-url' are inserted as href-values for the
// relevant link element.
// For example; The value of 'help-link-url' will be the new href-value
// for the element <a id="help-link"></a>.
const localizedElements = {
  nb: {
    "site-title": "Passordtjenester",
    "app-title": "Passordtjenester",
    "toggle-language": "English",
    "help-link": "Hjelp",
    "help-link-url":
      "https://www.uio.no/tjenester/it/brukernavn-passord/passordtjenester/",
    "contact-info-header": "Trenger du hjelp?",
    "contact-info-link-url":
      "https://www.uio.no/tjenester/it/brukernavn-passord/passordtjenester/",
    "contact-info-link": "Hjelp og kontaktinformasjon",
    "maintainer-header": "Ansvarlig for denne tjenesten",
    "maintainer-link":
      "Seksjon for integrasjoner og identiteter (INT)",
    "uu-status-link":
      "Tilgjengelighetserklæring",
    "terms-header":
      "Vilkår",
    "terms-cookies-link":
      "Informasjonskapsler",
    "terms-cookies-link-url":
      "https://www.uio.no/tjenester/it/brukernavn-passord/passordtjenester/hjelp/informasjonskapsler-i-passordtjenester.html",
    "logout-link": "Logg ut",
    "oidc-provider": "Innlogget med ID-porten "
  },
  en: {
    "site-title": "Password services",
    "app-title": "Password services",
    "toggle-language": "Norsk",
    "help-link": "Help",
    "help-link-url":
      "https://www.uio.no/english/services/it/username-password/password-services/",
    "contact-info-header": "Need help?",
    "contact-info-link-url":
      "https://www.uio.no/english/services/it/username-password/password-services/",
    "contact-info-link": "Support and contact information",
    "maintainer-header": "Maintained by",
    "maintainer-link":
      "Department for Integrations and Identities (INT)",
    "uu-status-link":
      "Accessibility statement (in Norwegian only)",
    "terms-header":
      "Terms",
    "terms-cookies-link":
      "Cookies",
    "terms-cookies-link-url":
      "https://www.uio.no/english/services/it/username-password/password-services/help/cookies-in-password-services.html",
    "logout-link": "Log out",
    "oidc-provider": "Logged in using ID-porten "
  }
};

export default {
  appSettings: {
    defaultLocale: "nb",
    minPasswordScore: 32,
    maxPasswordLength: 127,
    SMSTokenRequiresId: false,
    reCaptchaEnabled: false,
    reCaptchaKey: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
    showFeedbackPage: false,
    lowerCaseUsernameOnly: true,
    showIntroPage: false,
    changePasswordEnableNorwegianLettersInfo: true,
    disableNewUserPage: false,
    stripLastAtSignAndRestFromUsername: false,
    contactUrl: "https://www.uio.no/tjenester/it/kontakt/",
    otpEnabled: true,
    pwnedPasswordsEnabled: true,
    mfaResetEnabled: true,
    pinChangeEnabled: true,
    debounceDelay: 500
  },
  apiSettings: {
    apiBaseUrl: "/api",
    tokenExpiresAfter: 600,
    refreshTokenThreshold: 60,
    tokenExpiryWarningThreshold: 40,
    logoutEndpoint: "/logout",
    reqPassTokenByLoginEndpoint: "/authenticate",
    reqPassTokenBySMSTokenEndpoint: "/sms/verify",
    reqSMSTokenEndpoint: "/sms",
    reqUsernamesEndpoint: "/list-usernames",
    updatePasswordEndpoint: "/password",
    refreshTokenEndpoint: "/renew",
    userInfoEndpoint: "/user-info",
    pinCodeEndpoint: "/pin-code/",
    oidc: {
      idporten: {
        reqAuthEndpoint: "/oidc/authorize/idporten",
        logoutEndpoint: "/oidc/logout/idporten"
      },
      reqUsernamesEndpoint: "/oidc/list-usernames",
      updatePasswordEndpoint: "/oidc/password"
    },
    otp: {
      setSecretEndpoint: "/otp/set",
      delSecretEndpoint: "/otp/del",
      reqStatusEndpoint: "/otp/status"
    },
    azure_mfa: {
      delMethodsEndpoint: "/azure-mfa/",
      reqStatusAllEndpoint: "/azure-mfa/",
      reqStatusEndpoint: "/azure-mfa/"
    },
    hibp: {
      hibpEndpoint: "/hibp/"
    }
  },
  localizedElements,
  // Localized texts that are used from within the application.
  // These will be merged into the object returned by translations.js.
  // The tags will be available directly at the top-level from the
  // currently selected locale.
  appTexts: {
    nb: {
      institution: "Universitetet i Oslo",
      passwordDelay:
        "Det kan ta inntil en time før passordet vil fungere på alle tjenester ved UiO.",
      forgottenPasswordInfo:
        "Fyll inn feltene og få en engangskode på SMS. Mobilnummeret må være registrert i UiOs studentsystem, StudentWeb, eller i HR-portalen dersom du er ansatt.",
      newUserInfo:
        "Fyll inn feltene og få en engangskode på SMS. Mobilnummeret må være registrert i UiOs studentsystem, StudentWeb, eller i HR-portalen dersom du er ansatt.",
      requestUsernamesError:
        "Vi kunne ikke identifisere deg ut ifra oppgitt informasjon. Vennligst sjekk at du har fylt inn riktig nummer. Hvis du har reservert deg fra visning i UiOs personsøketjeneste, vil du motta brukernavnet ditt via SMS."
    },
    en: {
      institution: "University of Oslo",
      passwordDelay:
        "It may take up to one hour before your password will work on all services at the University of Oslo.",
      forgottenPasswordInfo:
        "Fill out the form and receive a one-time token by SMS. In order to use this service, your mobile phone number must be registrered in the University of Oslo's student system, StudentWeb, or the HR portal if you are an employee.",
      newUserInfo:
        "Fill out the form and receive a one-time token by SMS. In order to use this service, your mobile phone number must be registrered in the University of Oslo's student system, StudentWeb, or the HR portal if you are an employee.",
      requestUsernamesError:
        "We were unable to identify you based on the supplied information. Please verify that you have filled out the correct data. If you have reserved yourself from being shown to the public, you should receive an SMS shortly."
    }
  },
  mfaInstitutionTexts: {
    nb: {
      mfaInfoUrl: 'https://www.uio.no/tjenester/it/brukernavn-passord/2fa/index.html',
      mfaInfoPagename: 'Tofaktorautentisering på UiO.',
    },
    en: {
      mfaInfoUrl: 'https://www.uio.no/english/services/it/username-password/2fa/index.html',
      mfaInfoPagename: 'Two-Factor Authentication (2FA) at UiO.',
    }
  },
  changePasswordTexts: {
    nb: [
      'Du kan bygge opp passordet slik du vil. <a class="message-list__item__link" href="https://www.uio.no/tjenester/it/brukernavn-passord/passordtjenester/hjelp/b-krav.html">Vi anbefaler en setning</a>.',
      'Styrkeindikatoren viser når passordet er godkjent.',
      'Det kan ta inntil én time før passordet vil fungere på alle tjenester ved UiO.',
      'Tilkobling til wifi må <a class="message-list__item__link" href="https://www.uio.no/tjenester/it/nett/tradlost/hjelp/uioconfigwlan/index.html">konfigureres på nytt</a> etter passordendring.'
    ],
    en: [
      'Your password can be structured as you see fit. <a class="message-list__item__link" href="https://www.uio.no/english/services/it/username-password/password-requirements.html">We recommend using a sentence</a>.',
      'The strength indicator will show when the password is strong enough.',
      'It may take up to one hour before your password will work on all services at the University of Oslo.',
      'Connection to wifi must be <a class="message-list__item__link" href="https://www.uio.no/english/services/it/network/wireless/help/eduroam-settings.html">reconfigured</a> after password change.'
    ]
  },
  changePinTexts: {
    nb: [
      'Du kan bare bruke tall, ikke bokstaver eller andre tegn.',
      'PIN-koden kan ikke bestå av fire like siffer.',
      'PIN-koden kan ikke bestå av fire sekvensielle siffer.'
    ],
    en: [
      'Only use digits, not letters or other characters.',
      'The PIN code cannot consist of four identical digits.',
      'The PIN code cannot consist of four sequential digits.'
    ]
  },
  changePinUrls: {
    nb: "https://www.uio.no/om/finn-fram/apningstider/adgangskort.html",
    en: "https://www.uio.no/english/about/getting-around/opening-hours/access-card.html",
  },
  changePasswordNorwegianLettersInfo: {
    nb:
      'Det kan oppstå problemer dersom du bruker særnorske tegn som æ, ø og å, samt en del andre tegn i passordet. <a class="password-nor-letters-info-box__link" target="_blank" href="https://www.uio.no/tjenester/it/brukernavn-passord/passordtjenester/hjelp/godt-passord.html#ui-id-3">Les mer</a>.',
    en:
      'Problems may occur if you use certain characters like æ, ø and å, and some other special characters in the password. <a class="password-nor-letters-info-box__link" target="_blank" href="https://www.uio.no/english/services/it/username-password/help/password-requirements.html#ui-id-3">Read more</a>.'
  },

  // Hook functions for updating the site container when the user triggers
  // a change to the application settings, and vice versa via setting up
  // event-listeners on elements located in the site-container.
  hookFunctions: {
    updateLanguage: function (lang) {
      for (var id in localizedElements[lang]) {
        if (localizedElements[lang].hasOwnProperty(id)) {
          if (id.substring(id.length - 4, id.length) === "-url") {
            // Look up element the current id without the '-url'-postfix and set
            // the value of the id-key as the new href.
            document.getElementById(id.substring(0, id.length - 4)).href =
              localizedElements[lang][id];
          } else {
            document.getElementById(id).innerHTML = localizedElements[lang][id];
          }
        }
      }
    },
    attachToggleLanguageListener(callback) {
      document
        .getElementById("toggle-language")
        .addEventListener("click", callback);
    }
  }
};
