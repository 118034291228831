import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';

import pofhConfig from '../../pofhConfig';
import Page from '../../components/page';
import ButtonLink from '../../components/buttonlink';
import {
  frontpagePath,
  getSmsTokenPath,
  getUsernamesPath,
  newUserSMSPath,
} from '../../routeConstants';

const { apiBaseUrl, oidc } = pofhConfig.apiSettings;

const attributes = {
  forgottenUsername: {
    header: 'general.forgotten_username',
    info: 'general.forgotten_username_info',
    getButton: function getButton() {
      return (
        <ButtonLink
          href={getUsernamesPath}
          text={I18n.t(`general.get_username`)}
          wide={true}
          isRoute={true}
        />
      );
    },
  },
  forgottenUsernameOnlyOidc: {
    header: 'general.forgotten_username',
    info: 'general.forgotten_username_only_oidc_info',
    getButton: function getButton() {
      return null;
    },
  },
  forgottenPassword: {
    header: 'general.forgotten_password',
    info: 'general.select_recovery_method',
    getButton: function getButton() {
      return (
        <ButtonLink
          href={getSmsTokenPath}
          text={I18n.t(`general.use_sms_token`)}
          wide={true}
          isRoute={true}
        />
      );
    },
  },
  newUser: {
    header: 'general.new_user',
    info: 'general.new_user_common',
    getButton: function getButton() {
      return (
        <ButtonLink
          href={newUserSMSPath}
          text={I18n.t(`general.use_sms_token`)}
          wide={true}
          isRoute={true}
        />
      );
    },
  },
};

class OidcLogin extends React.Component {
  render() {
    return (
      <Page
        header={<Translate value={attributes[this.props.version].header} />}
        infoText={I18n.t(attributes[this.props.version].info)}
        goBackRoute={frontpagePath}
      >
        <div>
          {attributes[this.props.version].getButton()}
          <ButtonLink
            href={apiBaseUrl + oidc.idporten.reqAuthEndpoint}
            text={I18n.t('general.log_in_ext')}
            wide={true}
            idporten={true}
          />
        </div>
      </Page>
    );
  }
}

OidcLogin.propTypes = {
  locale: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
  };
}

export default connect(mapStateToProps)(OidcLogin);
