import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import spinner from '../../static/spinner.gif'

import {
  chooseUserPath,
  frontpagePath,
  mfaOffPath,
  changeOtpPath,
} from '../../routeConstants';

import Page from '../../components/page';
import ButtonLink from 'components/buttonlink';
import pofhConfig from '../../pofhConfig';

const { otpEnabled, mfaResetEnabled } = pofhConfig.appSettings;
const { apiBaseUrl, oidc } = pofhConfig.apiSettings;

const routeProps = {
  oidc: {
    goBackRoute: chooseUserPath,
    localmfa: changeOtpPath,
    localmfa_text: 'general.otp_header',
    azuremfa: mfaOffPath,
    azuremfa_text: 'general.reset_mfa_text.reset_mfa_header',
    idporten: false,
    isRouteCheck: true,
  },
  regular: {
    goBackRoute: frontpagePath,
    localmfa: apiBaseUrl + oidc.idporten.reqAuthEndpoint + '?next=change-otp',
    localmfa_text: 'general.log_in_ext',
    azuremfa: apiBaseUrl + oidc.idporten.reqAuthEndpoint + '?next=mfa-off',
    azuremfa_text: 'general.log_in_ext',
    idporten: true,
    isRouteCheck: false,
  }
}

class MfaInfo extends React.Component {
  constructor(props) {
    super(props);
  }

  getSubSections() {
    if (otpEnabled) {
      return [
        {
          children: (
            <ButtonLink
              href={routeProps[this.props.version].localmfa}
              text={I18n.t(routeProps[this.props.version].localmfa_text)}
              idporten={routeProps[this.props.version].idporten}
              isRoute={routeProps[this.props.version].isRouteCheck}
            />
          ),
          infoText: (
            <div>
              {I18n.t('general.otp_toggle_info')}
            </div>
          ),
          header: I18n.t('general.otp_header'),
        }
      ];
    }
    return [];
  }
  render() {
    const institution = mfaResetEnabled ? (pofhConfig.mfaInstitutionTexts[this.props.locale]) : (null);
    return mfaResetEnabled ? (
      <Page goBackRoute={routeProps[this.props.version].goBackRoute}
        subSections={this.getSubSections()}
      >
        <div className='sub-header'>{I18n.t('general.reset_mfa_text.reset_mfa_header')}</div>
        <div className='page__info-text'>
          {I18n.t('general.reset_mfa_text.azure_mfa_info')
            + I18n.t('general.reset_mfa_text.no_mfa_info')}
          <a href={institution.mfaInfoUrl}>
            {institution.mfaInfoPagename}
          </a>
        </div>
        <div>
          <ButtonLink
            href={routeProps[this.props.version].azuremfa}
            text={I18n.t(routeProps[this.props.version].azuremfa_text)}
            idporten={routeProps[this.props.version].idporten}
            isRoute={routeProps[this.props.version].isRouteCheck}
          />
        </div>
      </Page>
    ) : (
      <Page goBackRoute={routeProps[this.props.version].goBackRoute}>
        <img className='spinner' src={spinner} alt='spinner' />
      </Page>
    );
  }
}

MfaInfo.propTypes = {
  locale: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
  };
}

export default connect(mapStateToProps)(MfaInfo);
