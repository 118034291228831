import React from "react";
import PropTypes from 'prop-types';
import spinner from "../../static/spinner.gif";
import classNames from 'classnames';

const Button = ({
  text,
  onClick,
  disabled,
  type,
  fetching,
  noMargin,
  secondary,
  tabindex,
  wide,
}) => {
  const webKitLinux =
    window.navigator.userAgent.includes("WebKit") &&
    window.navigator.userAgent.includes("Linux");

  const fireFoxWin =
    window.navigator.userAgent.includes("Firefox") &&
    window.navigator.userAgent.includes("Windows");

  const fireFoxLinux =
    window.navigator.userAgent.includes("Firefox") &&
    window.navigator.userAgent.includes("Linux");

  const buttonClassNames = classNames({
    primaryButton: secondary === undefined,
    secondaryButton: secondary,
    buttonWebkit: webKitLinux,
    buttonFirefoxWin: fireFoxWin,
    buttonFirefoxLinux: fireFoxLinux,
    fetching: fetching,
    noMargin: noMargin,
    buttonWide: wide,
  });

  const spanClassNames = classNames({
    buttonText: true,
    buttonTextAlign: webKitLinux,
    hideText: fetching
  });

  return (
    <button className={buttonClassNames}
            disabled={disabled || fetching}
            type={type}
            tabIndex={tabindex}
            onClick={onClick}>
      <span className={spanClassNames}>{text}</span>
      {fetching && <img className="spinner" src={spinner} alt="spinner"/>}
    </button>
  )
};

Button.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  fetching: PropTypes.bool
};

export default Button;

export const ButtonGroup = ({ children }) => {
  const buttonGroupClassNames = classNames({
    "button-group": true,
    "button-group--single": !Array.isArray(children)
  });

  return <div className={buttonGroupClassNames}>{children}</div>;
};
