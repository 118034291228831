import React from 'react';

export const Popup = (props) => {
  return (
    <div className="popup-overlay">
      <div className="popup-wrapper">
        <div className="popup">
          { props.children }
        </div>
      </div>
    </div>
  );
};

export const PopupHeader = (props) => (
  <div className="popup-header">
    { props.children }
  </div>
);

export const PopupText = (props) => (
  <p className="popup-text">
    { props.children }
  </p>
);

export const PopupButtons = (props) => (
  <div className="popup-buttonContainer">
    { props.children }
  </div>
);

