import React from 'react';
import classNames from 'classnames';

const Label = (props) => {
  const labelClassNames = classNames({
    'text-input-label__label': true,
    'text-input-label__label--small': props.small
  });
  return (
    <div className="text-input-label">
      <label htmlFor={props.labelFor}
        className={labelClassNames}
        onClick={props.onClick}>
        {props.text}
        {props.required && <span className="text-input-label__req">*</span>}
      </label>
    </div>
  );
}

export default Label;
