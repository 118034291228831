import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import spinner from '../../static/spinner.gif'

import {
  frontpagePath,
} from '../../routeConstants';

import Page from '../../components/page';
import ButtonLink from 'components/buttonlink';
import pofhConfig from '../../pofhConfig';

const { pinChangeEnabled} = pofhConfig.appSettings;
const { apiBaseUrl, oidc } = pofhConfig.apiSettings;

class PinCodeInfo extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return pinChangeEnabled ? (
      <Page header={I18n.t('general.pin_code.header')}
            goBackRoute={frontpagePath}
            infoText={I18n.t('general.pin_code.info')}
      >
        <div className='page__info-text'>
          {I18n.t('general.pin_code.not_available')}
          <a href={pofhConfig.changePinUrls[this.props.locale]}>
          {I18n.t('general.pin_code.card_centre')}
          </a>
        </div>
        <ButtonLink
          href={apiBaseUrl + oidc.idporten.reqAuthEndpoint + '?next=change-pin'}
          text={I18n.t('general.log_in_ext')}
          idporten={true}
        />
      </Page>
    ) : (
      <Page goBackRoute={frontpagePath}>
        <img className='spinner' src={spinner} alt='spinner' />
      </Page>
    )
  }
}

PinCodeInfo.propTypes = {
  locale: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
  };
}

export default connect(mapStateToProps)(PinCodeInfo);
