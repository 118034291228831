import { START } from '../actionConstants';
import { I18n } from 'react-redux-i18n';

import { history } from '../configureStore';

export function apiCall(action) {
  return (dispatch) => {
    // dispatch(startAction(action.type));
    return dispatch({ apiCall: true, ...action });
  };
}

export function startAction(type) {
  return {
    type,
    status: START,
  };
}

export function redirectTo(path, state) {
  if (state) {
    history.push({ pathname: path, state: state });
  } else {
    history.push(path);
  }
}

export function toFeedbackSurvey() {
  window.location.href = I18n.t('contact.feedback_url');
}
