import React from 'react';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {Field, reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import {Line} from 'rc-progress';

import passwordCheck from '../../pwchecks/password-entropy';
import percentageToColor, {colors} from '../../pwchecks/percentageToColor';
import PasswordInput from '../passwordinput';
import Button, {ButtonGroup} from '../button';
import NorwegianLettersInfo from '../norwegianlettersinfo';

import { postHibpPasswordStatus } from 'actions';

import pofhConfig from '../../pofhConfig';
import Warning from '../warning';
const { minPasswordScore, maxPasswordLength } = pofhConfig.appSettings;

// Get the debounce delay from the config file, or use 500ms as default
// if not specified. Same for pwnedPasswordsEnabled, default disabled.
const debounceDelay = pofhConfig.appSettings.debounceDelay || 500;
const pwnedPasswordsEnabled = pofhConfig.appSettings.pwnedPasswordsEnabled || false;

const latin1Regex = /^[A-z0-9 \u00C0-\u00ff.,@/#?!$%+^&*;:'"{|}()<=>\-_`~]+$/;
const latin1ButNotAsciiRegex = /[\u00C0-\u00ff]+/;

const validate = (fields) => {
  const error = {};
  if (!fields.password)
    error.password = '';
  if (fields.password && passwordCheck(fields.password) < minPasswordScore)
    error.password = <Translate value="formErrors.passwordScoreTooLow" />;
  if (fields.password && fields.password.length > maxPasswordLength)
    error.password = <Translate 
                       value="formErrors.passwordTooLong"
                       length={maxPasswordLength}
                     />;
  if (!fields.confirmPassword)
    error.confirmPassword = '';
  if (fields.confirmPassword !== fields.password)
    error.confirmPassword = <Translate value="formErrors.passwordMismatch" />;
  if (fields.password && !latin1Regex.test(fields.password))
    error.password = <Translate value="formErrors.invalidPasswordChar" />;
  return error;
};

class ChangePasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.toggleShowNewPass = this.toggleShowNewPass.bind(this);
    this.toggleShowConfirmPass = this.toggleShowConfirmPass.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.state = {
      showNewPassword: false,
      showConfirmPassword: false,
      score: 0,
      entropyPercentage: 0,
      progressBarColor: colors[0],
      disableConfirmField: true,
      newPasswordHasNonAsciiLatin1Characters: false,
      norwegianLettersInfoDismissed: false,
      knownLeakedPassword: false,
      debounceTimeoutObject: null,
    }
  }
  toggleShowNewPass() {
    this.setState({showNewPassword: !this.state.showNewPassword})
  }
  toggleShowConfirmPass() {
    this.setState({showConfirmPassword: !this.state.showConfirmPassword})
  }

  checkHibpPasswordStatus(value){
    const payload = {
      password: value
    }
    this.setState({
      //make it show full red bar for user to indicate password being a known leaked password
      disableConfirmField: true,
      progressBarColor: colors[0] //set to index 0 to set color to red
    });
    this.props.postHibpPasswordStatus(payload)
    .then((res) => {
      if (res.response.knownLeaked){
        this.setState({
          //make it show full red bar for user to indicate password being a known leaked password
          knownLeakedPassword: true,
          disableConfirmField: true,
          progressBarColor: colors[0] //set to index 0 to set color to red
        });
      }
      else{
        this.setState({
          knownLeakedPassword: false,
          disableConfirmField: false,
          progressBarColor: colors[colors.length-1]
        });
      }
    })
  }

  onPasswordChange(value) {
    const score = passwordCheck(value);
    const percentage = (score / minPasswordScore) * 100;
    const color = percentageToColor(percentage);
    if (latin1ButNotAsciiRegex.test(value)) {
      this.setState({newPasswordHasNonAsciiLatin1Characters: true});
    } else {
      this.setState({newPasswordHasNonAsciiLatin1Characters: false});
    }
    this.setState({
      // Update the score and the progress bar color
      score: score,
      entropyPercentage: percentage,
      progressBarColor: color
    });
    if (percentage>=100) {
      if (pwnedPasswordsEnabled){
        // Only run this if pwnedPasswords is enabled

        // Debounce, clear the previous timeout
        clearTimeout(this.state.debounceTimeoutObject);
        this.setState({debounceTimeoutObject: setTimeout(() => {
          // Will check if weak password after debounce delay
          this.checkHibpPasswordStatus(value)
        }, debounceDelay)})
      }
      else{
        this.setState({
          // If pwnedPasswords is disabled, just enable confirm field
          disableConfirmField: false,
        });
      }
    }
  }
  render() {
    const submitDisabled = this.props.pristine || !this.props.valid ||
                           this.state.score < minPasswordScore;

    let currPassStr = null;
    if (0 < this.state.entropyPercentage &&
        this.state.entropyPercentage < 100)
      currPassStr = I18n.t('general.password_strength_not_approved');
    else if (this.state.entropyPercentage > 99)
      if (this.state.knownLeakedPassword)
        currPassStr = I18n.t('general.password_known_leaked');
      else
        currPassStr = I18n.t('general.password_strength_approved');

    return (
      <form onSubmit={this.props.handleSubmit}>
        <div className="position_relative">
          <Field name="password"
                label={I18n.t('general.new_password')}
                locale={this.props.locale}
                visible={this.state.showNewPassword}
                toggleText={!this.state.showNewPassword ?
                            I18n.t('general.show_password') :
                            I18n.t('general.hide_password')}
                toggleVisibility={this.toggleShowNewPass}
                onPasswordChange={this.onPasswordChange}
                component={PasswordInput}
                autofocus
                tabindex="1"
          />

          {pofhConfig.appSettings.changePasswordEnableNorwegianLettersInfo &&
            this.state.newPasswordHasNonAsciiLatin1Characters &&
            !this.state.norwegianLettersInfoDismissed &&
            <NorwegianLettersInfo
              locale={this.props.locale}
              onDismiss={() => this.setState({ norwegianLettersInfoDismissed: true })} />
          }
        </div>

        {this.state.entropyPercentage === 0 ?
          <svg className="line"
               viewBox="0 0 100 4"
               preserveAspectRatio="none">
            <path d="M 2,2 L 98,2"
                  stroke="#BCBCBC"
                  fillOpacity="0"
                  strokeLinecap="square"
                  strokeWidth="4" />
          </svg> :
          <Line percent={this.state.entropyPercentage}
                strokeWidth="4"
                strokeColor={this.state.progressBarColor}
                strokeLinecap="square"
                trailWidth="4"
                trailColor="#BCBCBC"
                className="line"
          />
        }
        
        {currPassStr ?
        <p className="line-text">{currPassStr}</p> :
        <p><br/></p>
        }
        
        <Field name="confirmPassword"
               label={I18n.t('general.repeat_password')}
               locale={this.props.locale}
               visible={this.state.showConfirmPassword}
               toggleText={!this.state.showConfirmPassword ?
                           I18n.t('general.show_password') :
                           I18n.t('general.hide_password')}
               toggleVisibility={this.toggleShowConfirmPass}
               component={PasswordInput}
               disabled={this.state.disableConfirmField}
               tabindex="2"
        />
        {this.props.error &&
          <Warning text={this.props.error} color="red" formWarning={true}/>
        }
        <ButtonGroup>
          {this.props.cancel && <Button onClick={this.props.cancel}
                  type='button'
                  text={I18n.t('general.cancel')}
                  secondary
                  tabindex="4"
          />}
          <Button type='submit'
                  text={I18n.t('general.confirm_password_change')}
                  fetching={this.props.submitting}
                  disabled={submitDisabled}
                  tabindex="3"
          />
        </ButtonGroup>
      </form>
    )
  }
}
ChangePasswordForm = reduxForm({ form: "changePasswordForm", validate })(
  ChangePasswordForm
)

ChangePasswordForm.propTypes = {
  cancel: PropTypes.func,
  locale: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,

  };
}

export default connect(mapStateToProps, {
  postHibpPasswordStatus,
})(ChangePasswordForm);
