import React from 'react';
import PropTypes from 'prop-types';
import {I18n, Translate} from 'react-redux-i18n';

import { Popup, PopupHeader, PopupText, PopupButtons } from '../popup';
import Button from '../button';

const CancelPopup = ({ yesAction, noAction }) => (
  <Popup>
    <PopupHeader><Translate value="general.cancel" /></PopupHeader>
    <PopupText><Translate value="general.cancel_popup_text" /></PopupText>
    <PopupButtons>
      <Button onClick={noAction}
              text={I18n.t('general.no')}
              secondary
      />
      <Button onClick={yesAction}
              text={I18n.t('general.yes')}
      />
    </PopupButtons>
  </Popup>
);

CancelPopup.propTypes = {
  yesAction: PropTypes.func.isRequired,
  noAction: PropTypes.func.isRequired,
};

export default CancelPopup;
