export const MOUNT_APP = "MOUNT_APP";

export const START = "START";
export const SUCCESS = "SUCCESS";
export const FAILURE = "FAILURE";

export const REQUEST_LOGIN = "REQUEST_LOGIN";
export const REQUEST_PASS_TOKEN_BY_SMS_TOKEN =
  "REQUEST_PASS_TOKEN_BY_SMS_TOKEN";
export const REQUEST_SMS_TOKEN = "REQUEST_SMS_TOKEN";
export const REQUEST_OIDC_AUTHORIZATION = "REQUEST_OIDC_AUTHORIZATION";
export const REQUEST_USERNAMES = "REQUEST_USERNAMES";
export const REQUEST_USER_INFO = "REQUEST_USER_INFO";
export const OIDC_REQUEST_USERNAMES = "OIDC_REQUEST_USERNAMES";

export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const SAVE_ID_NUMBER = "SAVE_ID_NUMBER";
export const SAVE_USERNAME = "SAVE_USERNAME";
export const SAVE_SESSION_TYPE = "SAVE_SESSION_TYPE";
export const SAVE_USER_INFO = "SAVE_USER_INFO";

export const LOGOUT = "LOGOUT";
export const BACKEND_LOGOUT = "BACKEND_LOGOUT";
export const SET_LOGOUT_MESSAGE = "SET_LOGOUT_MESSAGE";
export const CLEAR_LOGOUT_MESSAGE = "CLEAR_LOGOUT_MESSAGE";

export const CHECK_TOKEN_STATUS = "CHECK_TOKEN_STATUS";

export const UPDATE_SITE_CONTAINER_LANGUAGE = "UPDATE_SITE_CONTAINER_LANGUAGE";

export const SET_OTP_SECRET = "SET_OTP_SECRET"
export const DELETE_OTP_SECRET = "DELETE_OTP_SECRET";
export const REQUEST_OTP_STATUS = "REQUEST_OTP_STATUS";

export const REQUEST_MFA_STATUS = "REQUEST_MFA_STATUS";
export const REQUEST_ALL_MFA_STATUSES = "REQUEST_ALL_MFA_STATUSES";
export const DELETE_MFA_METHODS = "DELETE_MFA_METHODS";

export const GET_HIBP_STATUS = "GET_HIBP_STATUS";

export const SET_PIN_CODE = "SET_PIN_CODE";
export const GET_CARDHOLDER = "GET_CARDHOLDER";
