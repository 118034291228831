import React from "react";
import { Translate } from "react-redux-i18n";

import Link from "../link";
import LinkList from "../linklist";

const GoBackLink = ({ goBackRoute }) => (
  <LinkList goBackLink="true">
    <Link to={goBackRoute}>
      {<div className="go-back-arrow">
        <svg
          width="9px"
          height="14px"
          viewBox="-9 0 9 14"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            points="11.584641 0.0 6.717411 4.79573 1.923157 0.0 0.0 1.92242 6.717411 8.63983 13.507061 1.92316"
            transform="rotate(90)"
          />
        </svg>
      </div>}
      <Translate value="general.go_back" />
    </Link>
  </LinkList>
);

export default GoBackLink;
