import pofhConfig from './pofhConfig';

export default pofhConfig;

export const callSiteWrapperFunc = (funcName, params) => {
  if (!(pofhConfig.hookFunctions && pofhConfig.hookFunctions[funcName])) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(
        `Unable to locate window.pofhConfig.hookfunctions.${funcName}!`
      );
      return;
    }
  }
  pofhConfig.hookFunctions[funcName](...params);
};
