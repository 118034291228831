import React from 'react';
import classNames from 'classnames';
import GoBackLink from '../gobacklink';

const Content = ({
  children,
  header,
  infoText,
  goBackRoute,
  subSections,
  image,
  warning,
}) => {
  const contentClassNames = classNames({
    page: true,
    // "page--wide": route === "/intro",
  });
  return (
    <div className="page-outer">
      <div className="page-inner">
        {goBackRoute && <GoBackLink goBackRoute={goBackRoute} />}
        <section className={contentClassNames}>
          {image && image}
          <div className="page__header">{header}</div>
          {warning && warning}
          {infoText &&
            (typeof infoText === 'string' ? (
              <p
                className="page__info-text"
                dangerouslySetInnerHTML={{ __html: infoText }}
              />
            ) : (
              <p className="page__info-text">{infoText}</p>
            ))}
          {children}
          {subSections !== undefined &&
            subSections.map((subSection, index) => {
              return (
                <div className="subSection" key={index}>
                  <div className="sub-header">{subSection.header}</div>
                  {subSection.infoText &&
                    (typeof subSection.infoText === 'string' ? (
                      <p
                        className="page__info-text"
                        dangerouslySetInnerHTML={{
                          __html: subSection.infoText,
                        }}
                      />
                    ) : (
                      <div className="page__info-text">
                        {subSection.infoText}
                      </div>
                    ))}
                  {subSection.children}
                </div>
              );
            })}
        </section>
      </div>
    </div>
  );
};

export default Content;
