import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createRootReducer from './reducers/rootReducer';
import { syncTranslationWithStore, loadTranslations } from 'react-redux-i18n';
import throttle from 'lodash.throttle';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import { setLanguage } from './actions';

import apiMiddleware from './middleware/api';
import { userInfoBarMiddleware } from './middleware/userInfoBar';

import translations from './i18n/translations';
import { loadState, saveState } from './localStorage';

import pofhConfig from './pofhConfig';

const mergeTexts = (original, extra) => {
  const destructiveDeepMerge = (dest, src) => {
    for (const key of Object.keys(src)) {
      if (typeof src[key] === 'object' && src[key] !== 'null') {
        if (!dest[key] || typeof dest[key] !== 'object') {
          dest[key] = {};
        }
        destructiveDeepMerge(dest[key], src[key]);
      } else {
        dest[key] = src[key];
      }
    }
  };

  destructiveDeepMerge(original, extra);
  return original;
};
let tmp = mergeTexts(translations, pofhConfig.appTexts);
const mergedTranslations = mergeTexts(tmp, pofhConfig.localizedElements);

let composeEnhancers = compose;
if (process.env.NODE_ENV !== 'production')
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedState = loadState();

export const history = createBrowserHistory();

const store = createStore(
  createRootReducer(history),
  persistedState,
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(history),
      thunkMiddleware,
      apiMiddleware,
      userInfoBarMiddleware
    )
  )
);
export default function configureStore() {
  syncTranslationWithStore(store);
  store.dispatch(loadTranslations(mergedTranslations));
  store.dispatch(setLanguage(pofhConfig.appSettings.defaultLocale));

  // Persist changes to store every 1 sec
  store.subscribe(
    throttle(() => {
      saveState(store.getState());
    }, 1000)
  );
  return store;
}
