import React from 'react';
import classNames from 'classnames';

const LinkList = ({ children, goBackLink }) => {
  const linkListClassNames = classNames({
    "link-list": true,
    "go-back-list": goBackLink,
  })

  return(
    <div className={linkListClassNames}>
      { children }
    </div>
  )
};

export default LinkList;