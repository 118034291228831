import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import pofhConfig from '../../pofhConfig';
const { reCaptchaKey } = pofhConfig.appSettings;

const ReCaptcha = ({input}) => {
  return (
    <div className="recaptcha">
      <ReCAPTCHA
        sitekey={reCaptchaKey}
        onChange={input.onChange}
      />
    </div>
  )
};

export default ReCaptcha;