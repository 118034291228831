import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Page from '../../components/page';
import Button, { ButtonGroup } from '../../components/button';

import { frontpagePath } from '../../routeConstants';
import { redirectTo } from '../../actions/utils';
import { clearLogoutMessage } from '../../actions';

class LoggedOutPage extends React.Component {
  confirmLoggedOut() {
    this.props.clearLogoutMessage();
    redirectTo(frontpagePath)
  }
  render() {
    return (
      <Page header={this.props.logoutHeader}
        infoText={this.props.logoutMessage}>
        <ButtonGroup>
          <Button type='button'
            text="OK"
            onClick={this.confirmLoggedOut.bind(this)}
          />
        </ButtonGroup>
      </Page>
    )
  }
}

LoggedOutPage.propTypes = {
  locale: PropTypes.string,
  logoutMessage: PropTypes.string,
  clearLoggedOutMessage: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
    logoutMessage: state.session.logoutMessage,
    logoutHeader: state.session.logoutHeader
  }
}

export default connect(mapStateToProps, { clearLogoutMessage })(LoggedOutPage)
