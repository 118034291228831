import { authenticator } from "otplib";
import { keyEncoder } from "@otplib/plugin-thirty-two";

export function createOtpSecret() {
  let secret = new Uint8Array(10);
  window.crypto.getRandomValues(secret);
  return keyEncoder(secret);
}

export function validateToken(token, secret) {
  let isValid = false;
  try {
    isValid = authenticator.check(token, secret);
  } catch (err) {
    console.error(err);
  }
  return isValid;
}