import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Page from '../../components/page';
import { logoutPath, chooseUserPath } from '../../routeConstants';
import { Translate, I18n } from 'react-redux-i18n';
import ButtonLink from '../../components/buttonlink';
import Warning from '../../components/warning';
import logoutClock from '../../static/logout-clock.svg';

const routeProps = {
  oidc: {
    getButtons: function getButtons() {
      return (
        <div>
          <ButtonLink
            href={logoutPath}
            text={I18n.t('general.finish_and_logout')}
            wide
          />
          <ButtonLink
            href={chooseUserPath}
            text={I18n.t('general.after_password_change.continue')}
            isRoute
            type={'secondary'}
            wide
          />
        </div>
      );
    },
    getHeader: function getHeader() {
      return (
        <text>
          <Translate value="general.after_password_change.header_pt1" />
          <u>{this.props.location.state.username}</u>
          <Translate value="general.after_password_change.header_pt2" />
        </text>
      );
    },
  },
  regular: {
    getButtons: function getButtons() {
      return (
        <ButtonLink
          href={logoutPath}
          text={I18n.t('general.finish')}
          isRoute
          wide
        />
      );
    },
    getHeader: function getHeader() {
      return I18n.t('general.password_updated');
    },
  },
};

class AfterPasswordChange extends React.Component {
  constructor(props) {
    super(props);
    // Adding functions depending on the version specified by the route
    // containing the component.
    this.getButtons = routeProps[props.version].getButtons.bind(this);
    this.getHeader = routeProps[props.version].getHeader.bind(this);
  }
  render() {
    return (
      <Page
        header={this.getHeader()}
        warning={
          <Warning
            text={I18n.t('general.password_delay_notice')}
            color="yellow"
          />
        }
        image={
          <div className="popup-imageContainer">
            <img src={logoutClock} alt="" />
          </div>
        }
      >
        {this.getButtons()}
      </Page>
    );
  }
}

AfterPasswordChange.propTypes = {
  locale: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
  };
}

export default connect(mapStateToProps)(AfterPasswordChange);
