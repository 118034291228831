export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("pofhState");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const pofhState = {
      jwt: state.jwt,
      session: {
        sessionType: state.session.sessionType,
        userId: state.session.userId,
        idmSource: state.session.idmSource,
      },
    };
    const serializedState = JSON.stringify(pofhState);
    localStorage.setItem("pofhState", serializedState);
  } catch (err) {
    // Ignore write errors.
  }
};
