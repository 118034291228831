import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Page from '../../components/page';
import { I18n, Translate } from 'react-redux-i18n';
import spinner from '../../static/spinner.gif';
import Warning from 'components/warning';
import Infobox from 'components/infobox';

import {
  getAllMfaStatuses,
  getMfaStatus,
  delMfaMethods,
  storeSessionType,
} from '../../actions';

import Button, { ButtonGroup } from '../../components/button';
import { Popup, PopupHeader, PopupText } from '../../components/popup';
import pofhConfig from '../../pofhConfig';
import { oidcMfaInfoPath, logoutPath } from '../../routeConstants';
import { redirectTo } from 'actions/utils';
const { mfaResetEnabled } = pofhConfig.appSettings;

class MfaOffPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      fetchingUsers: true,
      showMfaPopup: false,
      showEndPopup: false,
      name: '',
      result: false,
    };

    this.showMfaPopup = this.showMfaPopup.bind(this);
    this.hideMfaPopup = this.hideMfaPopup.bind(this);

    this.showEndPopup = this.showEndPopup.bind(this);
    this.hideEndPopup = this.hideEndPopup.bind(this);

    this.resetMfaPopup = this.resetMfaPopup.bind(this);
    this.mfaResetDone = this.mfaResetDone.bind(this);

    this.handleTurnOffSubmit = this.handleTurnOffSubmit.bind(this);
    this.resetEndPopup = this.resetEndPopup.bind(this);
  }

  showMfaPopup(name) {
    this.setState({ showMfaPopup: true, name: name });
  }
  hideMfaPopup() {
    this.setState({ showMfaPopup: false, name: '' });
  }

  showEndPopup() {
    this.setState({ showEndPopup: true });
  }
  hideEndPopup() {
    this.setState({ showEndPopup: false });
    window.location.reload();
  }

  resetMfaPopup() {
    const currentUser = this.state.name;
    return (
      <Popup>
        <PopupHeader>
          <Translate value="general.reset_mfa_text.popup_header" />
        </PopupHeader>
        <PopupText>
          <Translate value="general.reset_mfa_text.popup_info" />
          {currentUser}
          {"."}
        </PopupText>
        <ButtonGroup>
          <Button
            type="button"
            onClick={this.hideMfaPopup}
            text={I18n.t('general.cancel')}
            secondary
          />
          <Button
            type="submit"
            onClick={this.handleTurnOffSubmit}
            text={I18n.t('general.reset_mfa_text.popup_confirm_off')}
          />
        </ButtonGroup>
      </Popup>
    );
  }

  handleTurnOffSubmit() {
    return this.props.delMfaMethods(this.state.name)
      .then((response) => {
        console.log(response)
        this.mfaResetDone(true)
      }).catch(() =>
        this.mfaResetDone(false))
  }

  resetEndPopup() {
    return this.state.result ? (
      <Popup>
        <Infobox text={I18n.t("general.reset_mfa_text.popup_reset_finished")} />
        <Button
          type="button"
          onClick={this.hideEndPopup}
          text={I18n.t('general.done')}
          wide
        />
      </Popup>
    ) : (
      <Popup>
        <Warning text={I18n.t("general.reset_mfa_text.popup_reset_error")} color="red" />
        <Button
          type="button"
          onClick={this.hideEndPopup}
          text={I18n.t('general.done')}
          wide
        />
      </Popup>
    )
  }

  mfaResetDone(result) {
    this.setState({ result: result });
    this.showEndPopup()
    this.hideMfaPopup()
  }

  componentDidMount() {
    if (!pofhConfig.apiSettings.azure_mfa){
      return;
    }
    return this.props.getAllMfaStatuses().then(
      (success) => {
        this.props.storeSessionType('pofh');
        this.setState({
          // The object returned by success.response consists
          // of {<username> : {<userEnabled>: <username>, <mfaEnabled>: bool }}
          // To get usernames in a consistent order, we transform
          // the object to an array and then sort them.
          users: Object.entries(success.response).sort(),
          fetchingUsers: false,
        });
      },
      (errorAction) => {
        const _error = {};
        if (errorAction.error.type === 'unauthorized') {
          redirectTo(logoutPath, {
            logoutHeader: 'general.logged_out',
            logoutMessageTag: 'general.warning_oidc_session_expired',
          });
        } else if (errorAction.error.type === 'not-found-error') {
          this.props.storeSessionType('pofh');
          this.setState({
            users: [],
            fetchingUsers: false,
          });
        } else if (errorAction.error.type === 'rate-limit-error') {
          _error._error = I18n.t('serverErrors.rateLimit');
        } else {
          _error._error = I18n.t('serverErrors.serverDown');
        }
      }
    );
  }

  getSubSections() {
    const institution = mfaResetEnabled ? (pofhConfig.mfaInstitutionTexts[this.props.locale]) : (null);
    const noMfaMethods = this.state.users.filter(
      ([, method]) => !method['mfaEnabled']
    );

    let subSections = [];

    if (noMfaMethods.length > 0 && !this.state.fetchingUsers) {
      subSections.push({
        children: (
          <div>
            <div className="otp-change-status-info">
              {I18n.t('general.reset_mfa_text.no_mfa_info')}
              {
                <a href={institution.mfaInfoUrl}>
                  {' '}
                  {institution.mfaInfoPagename}
                </a>
              }
            </div>
            <br></br>
            {noMfaMethods.map(([username]) => (
              <li key={username} className="username-list__list-item">
                {username}
              </li>
            ))}
          </div>
        ),
        header: I18n.t('general.reset_mfa_text.no_mfa_set'),
      });
    }

    return subSections;
  }

  render() {
    const usersWithMfa = this.state.users.filter(
      ([, method]) => method['mfaEnabled']
    );
    return mfaResetEnabled && !this.state.fetchingUsers ? (
      <Page
        header={I18n.t('general.reset_mfa_text.reset_mfa_header')}
        goBackRoute={oidcMfaInfoPath}
        subSections={this.getSubSections()}
      >
        {usersWithMfa && usersWithMfa.length > 0 ? (
          <div>
            <div className="otp-change-status-info">
              {I18n.t('general.reset_mfa_text.reset_mfa_info')}
            </div>
            {usersWithMfa.map(([username]) => (
              <Button
                type={'button'}
                href={{
                  state: { username: username },
                }}
                onClick={() => this.showMfaPopup(username)}
                text={username}
                wide
                key={username}
              />
            ))}
          </div>
        ) : (
          <div className="otp-change-status-info">
            {I18n.t('general.reset_mfa_text.no_users_found')}
          </div>
        )}
        <div>{this.state.showMfaPopup && this.resetMfaPopup()}</div>
        <div>{this.state.showEndPopup && this.resetEndPopup()}</div>
      </Page>
    ) : (
      <Page header={I18n.t('general.reset_mfa_text.reset_mfa_header')}>
        <img className="spinner" src={spinner} alt="spinner" />
      </Page>
    );
  }
}

MfaOffPage.propTypes = {
  getAllMfaStatuses: PropTypes.func.isRequired,
  getMfaStatus: PropTypes.func.isRequired,
  delMfaMethods: PropTypes.func.isRequired,
  storeSessionType: PropTypes.func.isRequired,
  locale: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
  };
}

export default connect(mapStateToProps, {
  getAllMfaStatuses,
  getMfaStatus,
  delMfaMethods,
  storeSessionType,
})(MfaOffPage);
