import React from 'react';
import PropTypes from 'prop-types';
import {I18n, Translate} from 'react-redux-i18n';

import pofhConfig from 'pofhConfig';
import { Popup, PopupHeader, PopupText} from '../popup';
import Button from '../button';

const ItRegulationPopup = ({yesAction, language}) => (
  <Popup>
    <PopupHeader><Translate value="general.itregulations.header" /></PopupHeader>
    <PopupText>
      {pofhConfig.appTexts[language].itRegulationText}
    </PopupText>
    <a href={pofhConfig.appTexts[language].itRegulationLink}>
      <PopupText>
        <Translate value="general.itregulations.linkText" /> 
      </PopupText>
    </a>
      <Button onClick={yesAction}
            type="button"
            text={I18n.t('general.itregulations.accept')}
            wide
      />
  </Popup>
);

ItRegulationPopup.propTypes = {
  yesAction: PropTypes.func.isRequired,
  language:PropTypes.func.isRequired,
};

export default ItRegulationPopup;
