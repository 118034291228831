import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const ArrowRight = (className) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
  >
    <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z" />
  </svg>
);

const ButtonLink = ({
  href,
  text,
  type,
  wide,
  idporten,
  trailingSymbol,
  isRoute,
}) => {
  const linkClassNames = classNames({
    primaryButton: type === 'primary' || type === undefined,
    secondaryButton: type === 'secondary',
    chooseUserButton: type === 'chooseUser',
    linkTypeButton: type === 'linkType',
    buttonWide: wide,
    buttonText: true,
  });

  if (isRoute) {
    return (
      <Link className={linkClassNames} to={href}>
        <p>{text}</p>
        {trailingSymbol === 'arrowRight' && (
          <div className="arrowRight">
            <ArrowRight />
          </div>
        )}
      </Link>
    );
  }
  return (
    <a className={linkClassNames} href={href}>
      {idporten && <div className="idportenLogo" />}
      <p>{text}</p>
    </a>
  );
};

export default ButtonLink;
