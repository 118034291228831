import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import configureStore, { history } from './configureStore';
import App from './screens/app';
import Frontpage from './screens/frontpage';
import Login from './screens/login';
import ChangePassword from './screens/changepassword';
import EnterToken from './screens/entertoken/index';
import GetUsernames from './screens/getusernames';
import ViewUserNames from './screens/viewusernames';
import LoggedOut from './screens/loggedout';
import Logout from './screens/logout';
import IntroPage from './screens/intro';
import GetSMSToken from './screens/getsmstoken';
import Feedback from './screens/feedback';
import ChooseUser from './screens/chooseuser';
import AfterPasswordChange from './screens/afterpasswordchange';
import OidcLogin from './screens/oidclogin';
import OtpOffPage from './screens/otpoff';
import OtpOn from './screens/otpon';
import ChangeOtpPage from './screens/changeotp';
import MfaOffPage from './screens/mfaoff';
import MfaInfo from './screens/mfainfo';
import ChangePinPage from './screens/changepin';
import PinCodeInfo from './screens/pincodeinfo';

import './styles/styles.scss';
import './styles/theme-styles/styles.scss';

import {
  frontpagePath,
  changePasswordPath,
  enterTokenPath,
  newUserPath,
  viewUserNamesPath,
  loggedOutPath,
  logoutPath,
  afterPasswordChangePath,
  oidcAfterPasswordChangePath,
  introPath,
  feedbackPath,
  getSmsTokenPath,
  forgottenUsernamePath,
  forgottenPasswordPath,
  chooseUserPath,
  oidcChangePasswordPath,
  newUserSMSPath,
  getUsernamesPath,
  otpOnPath,
  otpOffPath,
  loginPath,
  changeOtpPath,
  mfaOffPath,
  mfaInfoPath,
  oidcMfaInfoPath,
  changePinPath,
  pincodeInfoPath,

} from './routeConstants';

import pofhConfig from './pofhConfig';

const { disableNewUserPage, disableGetUsernames } = pofhConfig.appSettings;
const { oidc } = pofhConfig.apiSettings;

const store = configureStore();

const unknownRoute = (nextState, replace) => {
  replace(frontpagePath);
};

const checkAuth = (nextState, replace) => {
  const state = store.getState();
  if (state.session.sessionType === null) {
    // If we are here, it means you had a session, but it is now expired
    replace({ pathname: frontpagePath, state: { expiredSession: true } });
  } else if (state.session.sessionType !== 'pofh') {
    replace(frontpagePath);
  }
};

const checkOidcLogin = (nextState, replace) => {
  const state = store.getState();
  if (state.session.sessionType === null) {
    // If we are here, it means you had a session, but it is now expired
    replace({
      pathname: forgottenPasswordPath,
      state: { expiredSession: true },
    });
  } else if (state.session.sessionType !== 'pofh') {
    replace(forgottenPasswordPath);
  }
};

class index extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App>
            <Switch>
              <Route exact path="/" component={Frontpage} />
              {!disableGetUsernames && (
                <Route path={getUsernamesPath} component={GetUsernames} />
              )}
              <Route path={loginPath} component={Login} />
              <Route path={viewUserNamesPath} component={ViewUserNames} />
              <Route path={changeOtpPath} component={ChangeOtpPage} />
              <Route path={loggedOutPath} component={LoggedOut} />
              <Route path={logoutPath} component={Logout} />
              <Route path={introPath} component={IntroPage} />
              <Route path={afterPasswordChangePath}>
                <AfterPasswordChange version={'regular'} />
              </Route>
              <Route
                path={getSmsTokenPath}
                render={(routeProps) => (
                  <GetSMSToken
                    header="general.forgotten_password"
                    infoText="forgottenPasswordInfo"
                    location={routeProps.location}
                  />
                )}
              />
              {!disableNewUserPage && (
                <Route
                  path={newUserSMSPath}
                  render={(routeProps) => (
                    <GetSMSToken
                      header="general.new_user"
                      infoText="newUserInfo"
                      location={routeProps.location}
                    />
                  )}
                />
              )}
              {oidc && [
                !disableNewUserPage && (
                  <Route path={newUserPath} key={newUserPath}>
                    <OidcLogin version="newUser" />
                  </Route>
                ),
                <Route
                  path={otpOnPath}
                  component={OtpOn}
                  key={otpOnPath}
                  onEnter={checkOidcLogin}
                />,
                <Route
                  path={otpOffPath}
                  key={otpOffPath}
                  component={OtpOffPage}
                  onEnter={checkOidcLogin}
                />,
                <Route path={forgottenPasswordPath} key={forgottenPasswordPath}>
                  <OidcLogin version="forgottenPassword" />
                </Route>,
                disableGetUsernames ? (
                  <Route
                    path={forgottenUsernamePath}
                    key={forgottenUsernamePath}
                  >
                    <OidcLogin version="forgottenUsernameOnlyOidc" />
                  </Route>
                ) : (
                  <Route
                    path={forgottenUsernamePath}
                    key={forgottenUsernamePath}
                  >
                    <OidcLogin version="forgottenUsername" />
                  </Route>
                ),
                <Route
                  path={chooseUserPath}
                  component={ChooseUser}
                  key={chooseUserPath}
                />,
                <Route
                  path={mfaOffPath}
                  component={MfaOffPage}
                  key={mfaOffPath}
                />,
                <Route
                path={pincodeInfoPath}
                component={PinCodeInfo}
                key={pincodeInfoPath}
                />,
                <Route
                path={changePinPath}
                component={ChangePinPage}
                key={changePinPath}
                />,
                <Route
                  path={oidcChangePasswordPath}
                  key={oidcChangePasswordPath}
                  onEnter={checkOidcLogin}
                  render={(routeProps) => (
                    <ChangePassword
                      version={'oidc'}
                      location={routeProps.location}
                    />
                  )}
                />,
                <Route
                  path={oidcAfterPasswordChangePath}
                  key={oidcAfterPasswordChangePath}
                  onEnter={checkOidcLogin}
                  render={(routeProps) => (
                    <AfterPasswordChange
                      version={'oidc'}
                      location={routeProps.location}
                    />
                  )}
                />,
                <Route
                  path={oidcMfaInfoPath}
                  key={oidcMfaInfoPath}
                  onEnter={checkOidcLogin}
                  render={() => (<MfaInfo version={'oidc'} />)}
                />
              ]}
              <Route
                path={feedbackPath}
                component={Feedback}
                onEnter={checkAuth}
              />
              <Route
                path={enterTokenPath}
                component={EnterToken}
                onEnter={checkAuth}
              />
              <Route path={changePasswordPath} onEnter={checkAuth}>
                <ChangePassword version={'regular'} />
              </Route>
              <Route path={mfaInfoPath}>
                <MfaInfo version={'regular'} />
              </Route>
              <Route path="*" onEnter={unknownRoute} />
            </Switch>
          </App>
        </ConnectedRouter>
      </Provider>
    );
  }
}

ReactDOM.render(
  React.createElement(index),
  document.getElementById('app-container')
);

document.documentElement.classList.remove('loading');
