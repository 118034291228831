import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { EyeIcon } from '../icons';

const PasswordInput = ({
  visible,
  label,
  input,
  disabled,
  onPasswordChange,
  autofocus,
  toggleVisibility,
  toggleText,
  meta: { touched, error },
  hideError,
  tabindex,
}) => {
  const onChange = (event) => {
    input.onChange(event);
    if (onPasswordChange) onPasswordChange(event.target.value);
  };
  let passwordInput;
  const focus = () => {
    passwordInput.focus();
  };
  const passwordInputClassNames = classNames({
    'text-input__input': true,
    'text-input__input--error': !hideError && touched && error,
  });
  return (
    <div>
      <div className="text-input-label">
        <label
          htmlFor={input.name}
          className="text-input-label__label"
          onClick={focus}
        >
          {label}
        </label>
        <span
          tabIndex="-1"
          onClick={toggleVisibility}
          className="text-input-label__toggle-visibility"
        >
          {toggleText}
        </span>
      </div>
      <div className="text-input">
        {!hideError && touched && error && (
          <p className="text-input__error-text">{error}</p>
        )}
        <input
          type={visible ? 'text' : 'password'}
          autoComplete="off"
          className={passwordInputClassNames}
          disabled={disabled}
          autoFocus={autofocus}
          tabIndex={tabindex}
          id={input.name}
          autoCorrect="off"
          autoCapitalize="off"
          ref={(input) => (passwordInput = input)}
          {...input}
          onChange={onChange}
        />
        <EyeIcon
          onClick={toggleVisibility}
          error={!hideError && touched && error}
          visible={visible}
        />
      </div>
    </div>
  );
};

PasswordInput.propTypes = {
  visible: PropTypes.bool,
  label: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  onPasswordChange: PropTypes.func,
  toggleVisibility: PropTypes.func,
  toggleText: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
};

export default PasswordInput;
