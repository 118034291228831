import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { I18n, Translate } from 'react-redux-i18n';

import Page from '../../components/page';
import Button from '../../components/button';
import Messages from '../../components/messages';
import { changePasswordPath } from '../../routeConstants'


class IntroPage extends React.Component {
  render() {
    return (
      <Page header={ <Translate value="general.new_password_rules"/> }>
        <p><Translate value="general.intropage_text"/></p>
        <br/>
        <p><Translate value="general.intropage_rules_list_header"/></p>
        <Messages numbered
                  messages={[
          I18n.t('general.intropage_rule_1'),
          I18n.t('general.intropage_rule_2')
        ]} />
        <br/>
        <p><Translate value="general.intropage_allowed_categories"/></p>
        <Messages messages={[
                    I18n.t('general.sentences'),
                    I18n.t('general.words'),
                    I18n.t('general.numbers'),
                    I18n.t('general.special_chars'),
                    I18n.t('general.spaces')
                  ]}
        />
        <br/>
        <p><Translate value="general.category_use"/></p>
        <Link to={changePasswordPath}>
          <Button type="button"
                  text="OK"
          />
        </Link>
      </Page>
    )
  }
}

IntroPage.propTypes = {
  locale: PropTypes.string
};

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale
  }
}

export default connect(mapStateToProps)(IntroPage);
