/* @flow */
import React from 'react';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import classNames from 'classnames';
import Input from '../input';

class DropDown extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      inputValue: this.props.value,
      prevInputValue: ''
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ open: false });
      this.closeAndSetPrevValue();
    }
  }

  closeAndSetPrevValue() {
    this.setState({ open: false });
    if (this.state.inputValue === '' &&
        this.state.prevInputValue !== '') {
      this.setState({
        inputValue: this.state.prevInputValue,
        prevInputValue: ''
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value === '') {
      this.setState({ inputValue: '' })
    }
  }

  handleOnBlur() {
    // Redux-form's onBlur will also generate a change event
    this.closeAndSetPrevValue();
    if (this.props.onBlur) {
      this.props.onBlur(this.props.value);
    }
  }

  showList() {
    if (this.state.inputValue.length &&
        this.state.inputValue[0] === '+') {
      this.setState({
        inputValue: '',
        prevInputValue: this.state.inputValue
      });
    }
    this.setState({ open: true } )
  }

  onInputChange(event) {
    const newValue = event.target.value;
    this.setState({ inputValue: newValue });
    this.props.onChange(newValue);
  }

  onSelect(option) {
    const newValue = this.props.optionValues[option];
    this.setState({ inputValue: newValue, open: false });
    this.props.onChange(newValue);
  }

  render() {
    const { open, inputValue } = this.state;
    const {
      options, id, name, meta, label, required
    } = this.props;

    let validOptions = options;
    if (inputValue !== '') {
      validOptions = validOptions.filter(option => {
        return option.toLowerCase().includes(inputValue.toLowerCase())
      })
    }

    const dropdownClassNames = classNames({
      'dropdown': true,
    });

    const listClassNames = classNames({
      'dropdown--list': true,
      'dropdown--list-scroll': validOptions.length > 6
    });

    const listId = id + '-list';
    const inputId = id + '-input';
    const labelId = id + '-label';

    // The input element assumes it is used by Redux-Form directly,
    // so we have to wrap these attributes in an input-prop.
    const inputProp = {
      name: name,
      value: inputValue,
      onBlur: this.handleOnBlur.bind(this),
      onFocus: this.showList.bind(this),
      onChange: this.onInputChange.bind(this)
    };

    return (
      <div className="dropdown--container">
        <div className={ dropdownClassNames }
             aria-controls={ listId }
             aria-haspopup="true"
             aria-expanded={ open }
             ref={(node) => (this.wrapperRef = node)} >
          <Input
            id={ inputId }
            input={ inputProp }
            meta={ meta }
            label={ label }
            required={ required }
            smallLabel
            className="dropdown__input"
          />

          <ReactCSSTransitionGroup
            transitionName="fade-in-and-out"
            transitionEnterTimeout={ 500 }
            transitionLeaveTimeout={ 300 }>
            {open &&
            <ul className={ listClassNames }
                aria-labelledby={ labelId }
                id={ listId }>
              {validOptions.map(option => {
                return (
                  <li onClick={ this.onSelect.bind(this, option) }
                      key={ option }
                      className="dropdown--list--item">
                    { option }
                  </li>
                );
              })}
            </ul>
            }
          </ReactCSSTransitionGroup>
        </div>
      </div>
    )
  }
}


const DropDownRF = (props) => {
  const {input, ...remainingProps} = props;
  return (
    <DropDown
      value={input.value}
      id={input.name}
      name={input.name}
      onChange={input.onChange}
      onBlur={input.onBlur}
      { ...remainingProps }
    />
  )
};

export { DropDown, DropDownRF };