import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";
import { toFeedbackSurvey, redirectTo } from "../../actions/utils";
import { logoutPath } from "../../routeConstants";

import Page from "../../components/page";
import Button, { ButtonGroup } from "../../components/button";

class FeedbackPage extends React.Component {
  logout() {
    redirectTo(logoutPath, {
      logoutHeader: "general.password_changed",
      logoutMessageTag: "general.password_delay_notice",
    });
  }
  toFeedback() {
    toFeedbackSurvey();
  }
  render() {
    return (
      <Page header={<Translate value="general.password_changed" />}>
        <p>
          <Translate value="general.password_delay_notice" />
        </p>
        <h2 className="sub-header">
          <Translate value="general.feedback_page_header" />
        </h2>
        <p>
          <Translate value="general.feedback_page_info" />
        </p>
        <ButtonGroup>
          <Button
            type="button"
            secondary
            onClick={this.logout.bind(this)}
            text={<Translate value="general.feedback_page_decline" />}
          />
          <Button
            type="button"
            text={<Translate value="general.feedback_page_accept" />}
            onClick={this.toFeedback.bind(this)}
          />
        </ButtonGroup>
      </Page>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locale: state.i18n.locale,
  };
};

FeedbackPage.propTypes = {
  locale: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(FeedbackPage);
