import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { Translate, I18n } from 'react-redux-i18n';

import { updatePassword, logout } from '../../actions';
import { redirectTo } from '../../actions/utils';
import {
  feedbackPath,
  oidcAfterPasswordChangePath,
  afterPasswordChangePath,
  chooseUserPath,
  logoutPath,
} from '../../routeConstants';

import Page from '../../components/page';
import CancelPopup from '../../components/cancelpopup';
import ItRegulationPopup from 'components/itregulationpopup';
import Messages from '../../components/messages';
import Warning from '../../components/warning';
import Button, { ButtonGroup } from '../../components/button';
import { Popup, PopupHeader, PopupText } from '../../components/popup';
import ChangePasswordForm from '../../components/changepasswordform';

import pofhConfig from '../../pofhConfig';

const { showFeedbackPage, contactUrl, acceptItRulesEnabled } =
  pofhConfig.appSettings;

const routeProps = {
  oidc: {
    goBackRoute: chooseUserPath,
    onSubmit: function onSubmit(payload) {
      payload.username = this.props.location.state.username;

      return this.props
        .updatePassword({
          password: payload.password,
          username: payload.username,
        })
        .then(() => {
          if (showFeedbackPage) {
            redirectTo(feedbackPath);
          } else {
            redirectTo(oidcAfterPasswordChangePath, {
              username: this.props.location.state.username,
            });
          }
        }, this.handleError);
    },
    getHeader: function getHeader() {
      return (
        <div>
          <span>{I18n.t('general.change_password_for')}</span>
          <u>{this.props.location.state.username}</u>
        </div>
      );
    },
    showWarning: true,
  },
  regular: {
    onSubmit: function onSubmit(payload) {
      return this.props
        .updatePassword({
          password: payload.password,
        })
        .then(() => {
          if (showFeedbackPage) {
            redirectTo(feedbackPath);
          } else {
            this.props.logout();
            redirectTo(afterPasswordChangePath);
          }
        }, this.handleError);
    },
    getHeader: function getHeader() {
      return I18n.t('general.change_password');
    },
    cancelButton: true,
    showWarning: false,
  },
};

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCancelPopup: false,
      showPassphraseModal: false,
      showRegulationPopup: acceptItRulesEnabled,
    };
    this.logout = this.logout.bind(this);
    this.hideCancelPopup = this.hideCancelPopup.bind(this);
    this.showCancelPopup = this.showCancelPopup.bind(this);
    this.showPassphraseModal = this.showPassphraseModal.bind(this);
    this.hidePassphraseModal = this.hidePassphraseModal.bind(this);
    this.showItRegulationPopup = this.showItRegulationPopup.bind(this);
    this.hideItRegulationPopup = this.hideItRegulationPopup.bind(this);
    this.handleError = this.handleError.bind(this);
    // Adding functions depending on the version specified by the route
    // containing the component.
    this.onSubmit = routeProps[props.version].onSubmit.bind(this);
    this.getHeader = routeProps[props.version].getHeader.bind(this);
  }
  showCancelPopup() {
    this.setState({ showCancelPopup: true });
  }
  hideCancelPopup() {
    this.setState({ showCancelPopup: false });
  }
  showPassphraseModal() {
    this.setState({ showPassphraseModal: true });
  }
  hidePassphraseModal() {
    this.setState({ showPassphraseModal: false });
  }
  showItRegulationPopup() {
    this.setState({ showRegulationPopup: true });
  }
  hideItRegulationPopup() {
    this.setState({ showRegulationPopup: false });
  }

  logout() {
    redirectTo(logoutPath, {
      logoutHeader: 'general.logged_out',
      logoutMessageTag: 'general.now_logged_out',
    });
  }

  handleError(errorAction) {
    const _error = {};
    if (errorAction.error.type === 'unauthorized') {
      redirectTo(logoutPath, {
        logoutHeader: 'general.logged_out',
        logoutMessageTag: 'general.warning_oidc_session_expired',
      });
    } else if (errorAction.error.type === 'missing-session') {
      _error._error = <Translate value="serverErrors.missingSession" />;
    } else if (errorAction.error.type === 'invalid-new-password') {
      _error._error = <Translate value="serverErrors.invalidNewPassword" />;
    } else if (errorAction.error.type === 'new-password-contains-username') {
      _error._error = (
        <Translate value="serverErrors.newPasswordContainsUsername" />
      );
    } else if (errorAction.error.type === 'new-password-contains-personname') {
      _error._error = (
        <Translate value="serverErrors.newPasswordContainsPersonname" />
      );
    } else if (errorAction.error.type === 'rate-limit-error') {
      _error._error = <Translate value="serverErrors.rateLimit" />;
    } else {
      _error._error = <Translate value="serverErrors.serverDown" />;
    }
    throw new SubmissionError(_error);
  }

  render() {
    return (
      <Page
        header={this.getHeader()}
        goBackRoute={routeProps[this.props.version].goBackRoute}
        warning={
          routeProps[this.props.version].showWarning &&
          !this.props.location.state.isActive && (
            <Warning
              text={I18n.t('general.warning_account_locked')}
              color="red"
            >
              <a href={contactUrl}>
                <Translate value="general.contact_help" />
              </a>
            </Warning>
          )
        }
      >
        {this.state.showRegulationPopup && (
          <ItRegulationPopup
            yesAction={this.hideItRegulationPopup}
            language={this.props.locale}
          />
        )}
        <Messages
          messages={pofhConfig.changePasswordTexts[this.props.locale]}
        />
        <ChangePasswordForm
          onSubmit={this.onSubmit}
          cancel={
            routeProps[this.props.version].cancelButton && this.showCancelPopup
          }
        />
        {this.state.showCancelPopup && (
          <CancelPopup
            noAction={this.hideCancelPopup}
            yesAction={this.logout}
          />
        )}
        {this.state.showPassphraseModal && (
          <Popup>
            <PopupHeader>
              <Translate value="general.passphrase_modal_header" />
            </PopupHeader>
            <PopupText>
              <Translate value="general.passphrase_tip" />
            </PopupText>
            <ButtonGroup>
              <Button onClick={this.hidePassphraseModal} text="OK" />
            </ButtonGroup>
          </Popup>
        )}
      </Page>
    );
  }
}

ChangePassword.propTypes = {
  locale: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
  };
}

export default connect(mapStateToProps, {
  logout,
  updatePassword,
})(ChangePassword);
