import fetch from 'isomorphic-fetch';
import { SUCCESS, FAILURE } from '../actionConstants';

import pofhConfig from '../pofhConfig';
const { apiBaseUrl } = pofhConfig.apiSettings;

function callApi(endpoint, method, payload, langCode) {
  const config = {method};
  let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Accept-Language': langCode
  };

  config['headers'] = headers;

  if (payload)
    config['body'] = JSON.stringify(payload);
  return fetch(apiBaseUrl + endpoint, config)
  .then(
    response => {
      if (!response.ok)
        return response.json().then(error => Promise.reject(error));
      if (response.status === 204)
        return true;
      return response.json()
    },
    error => {
      return Promise.reject(error);
    })
  .then(
    json => {
      return json;
    });
}

export default store => next => action => {
  // So the middleware doesn't get applied to every single action
  if (!action.apiCall) {
    return next(action)
  }
  // Get language settings
  const state = store.getState();
  const langCode = state.i18n.locale;

  return callApi(action.endpoint, action.method, action.payload, langCode)
    .then(
      response => {
        return next({
          status: SUCCESS,
          response,
          ...action
      });
    },
    error => {
      return Promise.reject(next({
        status: FAILURE,
        ...action,
        error: {
          type: error.error,
          details: error.details
        }
      }));
    }
  )
}
