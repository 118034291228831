import React from 'react';
import classNames from 'classnames';
import Label from '../label';

const NumberInput = ({
  input, type, label, meta: { touched, error }, hideError, autofocus,
  required, tabindex, smallLabel, className, inputMode, pattern
}) => {
  const extraInputClassName = className ? className : '';
  const inputClassNames = classNames({
    [extraInputClassName]: true,
    'text-input__input': true,
    'text-input__number': true,
    'text-input__input--error': !hideError && touched && error
  });

  let textInput = null;
  const focus = () => {
    textInput.focus();
  };
  return (
    <div className="number-input">
      {!!label &&
        <Label
          labelFor={input.name}
          required={required}
          text={label}
          onClick={focus}
          small={smallLabel}
        />
      }
      <input 
        type={type}
        className={inputClassNames}
        tabIndex={tabindex}
        id={input.name}
        autoComplete="off"
        ref={(node) => (textInput = node)}
        autoFocus={autofocus}
        autoCorrect="off"
        autoCapitalize="off"
        inputMode={inputMode}
        pattern={pattern}
        {...input} />
      {!hideError && touched && error &&
        <span className="text-input__error-text">{error}</span>
      }
    </div>
  )
};

export default NumberInput;
