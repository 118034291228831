import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Page from '../../components/page';
import ToggleOtpForm from 'components/toggleotpform';
import pofhConfig from '../../pofhConfig';
import { oidcMfaInfoPath } from 'routeConstants';
const { otpEnabled } = pofhConfig.appSettings;

class ChangeOtpPage extends React.Component {
  render() {
    return (
      <Page
        goBackRoute={oidcMfaInfoPath}
      >
        {otpEnabled && (
          <ToggleOtpForm hasOidcLogin={'true'} />
        )}
      </Page>
    );
  }
}

ChangeOtpPage.propTypes = {
  locale: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
  };
}

export default connect(mapStateToProps)(ChangeOtpPage);
