import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { I18n, Translate } from "react-redux-i18n";
import { SubmissionError } from "redux-form";

import {
  fetchPassTokenBySMSToken,
  fetchUserInfo,
  storeUserInfo,
} from "../../actions";
import { changePasswordPath, logoutPath } from "../../routeConstants";
import { redirectTo } from "../../actions/utils";

import Page from "../../components/page";
import EnterTokenForm from "../../components/entertokenform";
import CancelPopup from "../../components/cancelpopup";

class EnterToken extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = { showCancelPopup: false };
    this.logout = this.logout.bind(this);
    this.hideCancelPopup = this.hideCancelPopup.bind(this);
    this.showCancelPopup = this.showCancelPopup.bind(this);
  }
  showCancelPopup() {
    this.setState({ showCancelPopup: true });
  }
  hideCancelPopup() {
    this.setState({ showCancelPopup: false });
  }
  logout() {
    redirectTo(logoutPath);
  }

  onSubmit(payload) {
    return this.props.fetchPassTokenBySMSToken({ nonce: payload.token }).then(
      () => {
        this.props.fetchUserInfo().then(
          (success) => {
            this.props.storeUserInfo(
              success.response.identifier,
              success.response.idm_source
            );
          },
          () => {
            // Fail silently
            this.props.storeUserInfo(null, null);
          }
        );
        redirectTo(changePasswordPath);
      },
      (errorAction) => {
        const _error = {};
        if (errorAction.error.type === "invalid-nonce") {
          _error._error = I18n.t("serverErrors.invalidSmsToken");
        } else if (errorAction.error.type === "rate-limit-error") {
          _error._error = I18n.t("serverErrors.rateLimit");
        } else {
          _error._error = I18n.t("serverErrors.serverDown");
        }
        throw new SubmissionError(_error);
      }
    );
  }
  render() {
    return (
      <Page
        header={<Translate value="general.enter_token" />}
        infoText={<Translate value="general.enter_token_info" />}
      >
        <EnterTokenForm
          onSubmit={this.onSubmit}
          cancel={this.showCancelPopup}
        />
        {this.state.showCancelPopup && (
          <CancelPopup
            noAction={this.hideCancelPopup}
            yesAction={this.logout}
          />
        )}
      </Page>
    );
  }
}

EnterToken.propTypes = {
  locale: PropTypes.string,
  fetchPassTokenBySMSToken: PropTypes.func.isRequired,
  fetchUserInfo: PropTypes.func.isRequired,
  storeUserInfo: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
  };
}

export default connect(mapStateToProps, {
  fetchPassTokenBySMSToken,
  fetchUserInfo,
  storeUserInfo,
})(EnterToken);
