import { combineReducers } from 'redux';
import { i18nReducer } from 'react-redux-i18n';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';
import session from './session';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    i18n: i18nReducer,
    form: formReducer,
    session,
  });

export default createRootReducer;
