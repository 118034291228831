import React from 'react';

import { InfoIcon, CloseIcon } from '../icons';

import pofhConfig from '../../pofhConfig';

const NorwegianLettersInfo = ({ locale, onDismiss }) => {
  return (
    <div className="password-nor-letters-info-container">
      <div className="password-nor-letters-info-arrow" />
      <div className="password-nor-letters-info-box">
        <div className="password-nor-letters-info-box__info-icon"><InfoIcon /></div>
        <div className="password-nor-letters-info-box__text"
          dangerouslySetInnerHTML={{
            __html: pofhConfig.changePasswordNorwegianLettersInfo[locale]
          }}>
        </div>
        <div className="password-nor-letters-info-box__close-icon">
          <button type='button' onMouseDown={onDismiss}>
            <CloseIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default NorwegianLettersInfo;
