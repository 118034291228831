export const frontpagePath = "/";
export const loginPath = "/login";
export const changePasswordPath = "/changePassword";
export const enterTokenPath = "/enterToken";
export const newUserPath = "/newUser";
export const viewUserNamesPath = "/usernames";
export const loggedOutPath = "/loggedOut";
export const logoutPath = "/logout";
export const afterPasswordChangePath = "/afterPasswordChange";
export const oidcAfterPasswordChangePath = "/oidcAfterPasswordChange";
export const oidcLoggedOutPath = "/oidcLoggedOut";
export const introPath = "/intro";
export const feedbackPath = "/feedback";
export const oidcChangePasswordPath = "/oidcChangePassword";
export const getSmsTokenPath = "/getToken";
export const newUserSMSPath = "/newUserSMSToken";
export const forgottenPasswordPath = "/forgottenPassword";
export const forgottenUsernamePath = "/forgottenUsername";
export const getUsernamesPath = "/getUsernames";
export const chooseUserPath = "/chooseUser";
export const otpOffPath = "/otpOff";
export const otpOnPath = "/otpOn";
export const changeOtpPath = "/changeOtp";
export const mfaOffPath = "/mfaOff";
export const mfaInfoPath = "/mfaInfo";
export const oidcMfaInfoPath = "/oidcMfaInfo";
export const changePinPath = "/changePin";
export const pincodeInfoPath = "/pincodeInfo";

