import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { Field, reduxForm } from 'redux-form';
import Input from '../input';
import Button, { ButtonGroup } from '../button';
import Warning from '../warning';

const validate = (fields) => {
  const error = {};
  if (!fields.token)
    error.token = <Translate value='formErrors.requiredField' />;
  return error;
};

class EnterTokenForm extends React.Component {
  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Field name="token"
          type="text"
          label={I18n.t('general.token')}
          component={Input}
        />
        {this.props.error &&
          <Warning text={this.props.error} color="red" formWarning={true} />
        }
        <ButtonGroup>
          <Button onClick={this.props.cancel}
            type='button'
            text={I18n.t('general.cancel')}
            secondary
          />
          <Button type='submit'
            text={I18n.t('general.continue')}
            fetching={this.props.submitting}
            disabled={this.props.pristine || !this.props.valid}
          />
        </ButtonGroup>
      </form>
    )
  }
}
EnterTokenForm = reduxForm({ form: 'enterTokenForm', validate })(
  EnterTokenForm
)

EnterTokenForm.propTypes = {
  cancel: PropTypes.func,
  locale: PropTypes.string
};

export default connect((state) => {
  return {
    locale: state.i18n.locale
  };
})(EnterTokenForm);
