import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { frontpagePath } from '../../routeConstants';
import { redirectTo } from '../../actions/utils';
import { backendLogout, logout, setLogoutMessage } from '../../actions';
import spinner from '../../static/spinner.gif';

import pofhConfig from '../../pofhConfig';

const { oidc, apiBaseUrl } = pofhConfig.apiSettings;

class Logout extends React.Component {
  UNSAFE_componentWillMount() {
    let logoutHeader = "general.logged_out";
    let logoutMessageTag = "general.now_logged_out";

    if (this.props.location.state) {
      logoutHeader =
        this.props.location.state.logoutHeader || "general.logged_out";
      logoutMessageTag =
        this.props.location.state.logoutMessageTag || "now_logged_out";
    }
    // We need to make a copy of idmSource if we want to redirect to the
    // oidc logout page.
    // props.idmSource gets deleted when we call props.logout.
    const idmSource = this.props.idmSource;

    this.props.logout();
    // This is a bit silly.. We only save state to the local storage
    // every 1s, so we need to wait a bit here to ensure that the
    // changes are stored..
    setTimeout(() => {
      if (idmSource === "id_porten") {
        window.location.pathname = apiBaseUrl + oidc.idporten.logoutEndpoint;
      } else {
        this.props.backendLogout().then(
          () => {
            this.props.setLogoutMessage(logoutHeader, logoutMessageTag);
            redirectTo(frontpagePath);
          },
          () => {
            redirectTo(frontpagePath);
          }
        );
      }
    }, 1000);
  }

  render() {
    return (
      <div>
        <img className="spinner" src={spinner} alt="spinner" />
      </div>
    );
  }
}

Logout.propTypes = {
  backendLogout: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  setLogoutMessage: PropTypes.func.isRequired,
  locale: PropTypes.string,
  idmSource: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
    idmSource: state.session.idmSource,
  };
}

export default connect(mapStateToProps, {
  backendLogout,
  logout,
  setLogoutMessage,
})(Logout);
