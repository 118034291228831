import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import spinner from '../../static/spinner.gif';

import {
  fetchOidcUserNames,
  fetchUserInfo,
  storeSessionType,
  storeUserInfo,
} from '../../actions';

import Page from '../../components/page';
import ButtonLink from '../../components/buttonlink';
import { redirectTo } from 'actions/utils';
import { oidcChangePasswordPath, logoutPath, oidcMfaInfoPath, changePinPath } from '../../routeConstants';
import pofhConfig from '../../pofhConfig';

const { contactUrl, mfaResetEnabled, pinChangeEnabled } = pofhConfig.appSettings;

class ChooseUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = { users: [], fetchingUsers: true };
    this.getSubSections = this.getSubSections.bind(this);
  }

  UNSAFE_componentWillMount() {
    return this.props.fetchOidcUserNames().then(
      (success) => {
        this.props.storeSessionType('pofh');
        this.props.fetchUserInfo().then(
          (success) => {
            this.props.storeUserInfo(
              success.response.identifier,
              success.response.idm_source
            );
          },
          () => {
            // Fail silently
            this.props.storeUserInfo(null, null);
          }
        );

        this.setState({
          // The object returned by success.response consists
          // of {<username>: <isActive>}.
          // To get usernames in a consistent order, we transform
          // the object to an array and then sort them.
          users: Object.entries(success.response).sort(),
          fetchingUsers: false,
        });
      },
      (errorAction) => {
        const _error = {};
        if (errorAction.error.type === 'unauthorized') {
          redirectTo(logoutPath, {
            logoutHeader: 'general.logged_out',
            logoutMessageTag: 'general.warning_oidc_session_expired',
          });
        } else if (errorAction.error.type === 'not-found-error') {
          // We want to display the userInfoBar when there are no users
          this.props.storeSessionType('pofh');
          this.props.fetchUserInfo().then(
            (success) => {
              this.props.storeUserInfo(
                success.response.identifier,
                success.response.idm_source
              );
            },
            () => {
              // Fail silently
              this.props.storeUserInfo(null, null);
            }
          );
          this.setState({
            users: [],
            fetchingUsers: false,
          });
        } else if (errorAction.error.type === 'rate-limit-error') {
          _error._error = I18n.t('serverErrors.rateLimit');
        } else {
          _error._error = I18n.t('serverErrors.serverDown');
        }
      }
    );
  }

  getSubSections() {
    const lockedUsers = this.state.users.filter(([, isActive]) => !isActive);
    let subSections = [];

    if (lockedUsers.length > 0 && !this.state.fetchingUsers) {
      subSections.push({
        children: lockedUsers.map(([username, isActive]) => (
          <ButtonLink
            href={{
              pathname: oidcChangePasswordPath,
              state: { username: username, isActive: isActive },
            }}
            text={username}
            wide
            isRoute
            trailingSymbol={'arrowRight'}
            key={username}
            type={'chooseUser'}
          />
        )),
        infoText: (
          <div>
            {I18n.t('general.choose_user.suspended_users_info')}
            <a href={contactUrl}>
              <Translate value="general.contact_help_short" />
            </a>
          </div>
        ),
        header: I18n.t('general.choose_user.suspended_users'),
      });
    }

    if (mfaResetEnabled && !this.state.fetchingUsers) {
      subSections.push({
        children:
          <ButtonLink
            href={{ pathname: oidcMfaInfoPath }}
            text={I18n.t('general.mfa_page_header')}
            isRoute
          />
      })
    }
    if (pinChangeEnabled && !this.state.fetchingUsers) {
      subSections.push({
        children:
        <ButtonLink
          href={{ pathname: changePinPath}}
          text={I18n.t('general.pin_code.header')}
          isRoute
        />
      })
    }
    return subSections;
  }

  render() {
    return !this.state.fetchingUsers && this.state.users.length === 0 ? (
      <Page
        header={`${I18n.t('general.choose_user.no_users')} ${I18n.t(
          'institution'
        )}`}
        infoText={
          <a href={contactUrl}>
            <Translate value="general.contact_help" />
          </a>
        }
      >
        <ButtonLink
          href={logoutPath}
          text={I18n.t('general.finish_and_logout')}
          wide
        />
      </Page>
    ) : (
      <div>
        <Page
          header={I18n.t('general.choose_user.choose_user')}
          subSections={this.getSubSections()}
        >
          {this.state.fetchingUsers ? (
            <img className="spinner" src={spinner} alt="spinner" />
          ) : (
            this.state.users
              .filter(([, isActive]) => isActive)
              .map(([username, isActive]) => (
                <ButtonLink
                  href={{
                    pathname: oidcChangePasswordPath,
                    state: { username: username, isActive: isActive },
                  }}
                  text={username}
                  wide
                  isRoute
                  trailingSymbol={'arrowRight'}
                  key={username}
                  type={'chooseUser'}
                />
              ))
          )}
        </Page>
      </div>
    );
  }
}

ChooseUser.propTypes = {
  fetchOidcUserNames: PropTypes.func.isRequired,
  fetchUserInfo: PropTypes.func.isRequired,
  storeSessionType: PropTypes.func.isRequired,
  storeUserInfo: PropTypes.func.isRequired,
  locale: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
  };
}

export default connect(mapStateToProps, {
  fetchOidcUserNames,
  fetchUserInfo,
  storeSessionType,
  storeUserInfo,
})(ChooseUser);
