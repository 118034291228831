import React from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { I18n, Translate } from "react-redux-i18n";
import { Field, reduxForm } from "redux-form";

import Input from "../input";
import Button, { ButtonGroup } from "../button";
import ReCaptcha from "../recaptcha";

import pofhConfig from "../../pofhConfig";
import Warning from "../warning";
const { reCaptchaEnabled } = pofhConfig.appSettings;

const validate = fields => {
  const error = {};

  if (fields.identifier && ![6, 11].includes(fields.identifier.length))
    error.identifier = <Translate value="formErrors.identifierLength" />;

  if (fields.identifier && !/^[0-9]+$/.test(fields.identifier))
    error.identifier = <Translate value="formErrors.onlyNumbers" />;

  if (!fields["g-recaptcha-response"])
    error["g-recaptcha-response"] = (
      <Translate value="formErrors.requiredField" />
    );

  return error;
};

const identifierLabel = () => (
  <span>
    <Translate value="general.student_nr" /> /
    <br />
    <Translate value="general.social_security_nr" />
  </span>
);

class ForgottenUserNameForm extends React.Component {
  render() {
    const submitDisabled = this.props.pristine || !this.props.valid;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Field
          name="identifier"
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          label={identifierLabel()}
          locale={this.props.locale}
          component={Input}
          autofocus
          required
        />
        {reCaptchaEnabled && (
          <Field name="g-recaptcha-response" component={ReCaptcha} />
        )}
        {this.props.error && (
          <Warning text={this.props.error} color="red" formWarning={true} />
        )}
        <ButtonGroup>
          <Button
            onClick={this.props.cancel}
            type="button"
            text={I18n.t("general.cancel")}
            secondary
          />
          <Button
            type="submit"
            text={I18n.t("general.continue")}
            fetching={this.props.submitting}
            disabled={submitDisabled}
          />
        </ButtonGroup>
      </form>
    );
  }
}
ForgottenUserNameForm = reduxForm({ form: "forgottenUsernameForm", validate })(
  ForgottenUserNameForm
)

ForgottenUserNameForm.propTypes = {
  locale: PropTypes.string
};

export default connect(state => {
  return {
    locale: state.i18n.locale
  };
})(ForgottenUserNameForm);
