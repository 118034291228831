/**
 * Test module for password entropy score.
 *
 *  The password entropy score is implemented as a dynamic entropy calculator
 *  similar to the one suggested by NIST in 2004.
 */

const CHAR_SETS = {
    uppercase: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
    lowercase: "abcdefghijklmnopqrstuvwxyz",
    digits: "0123456789",
    punctuation: "!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~ "
};

export default function get_score(password) {
    return get_base_score(password) + get_bonus_score(password);
}


function get_base_score(password) {
    let score = 0.0;
    for (let i = 0; i < password.length; i++) {
        if (i < 1) {
            score += 4.0;
        } else if (i < 8) {
            score += 2.0;
        } else if (i < 20) {
            score += 1.5;
        } else {
            score += 1.0;
        }
    }
    return score;
}


function get_bonus_score(password) {

    const required_groups = 3;
    const required_chars_per_group = 2;

    let char_count = {
        uppercase: 0,
        lowercase: 0,
        digits: 0,
        punctuation: 0
    };

    for (let i = 0; i < password.length; i++) {
        for (let key in CHAR_SETS) {
            if (CHAR_SETS[key].indexOf(password.charAt(i)) > -1) {
                char_count[key] += 1;
                break;
            }
        }
    }

    // now let's count the results...
    let groups = 0;
    let chars_per_group_cnt = 0;
    for (let key in char_count) {
        if (char_count[key] >= required_chars_per_group) {
            chars_per_group_cnt++;
        }
        if (char_count[key] >= 1) {
            groups++;
        }
    }

    if (chars_per_group_cnt >= required_groups) {
        return 8.0;
    }

    if (groups >= required_groups) {
        return 6.0;
    }

    return 0.0;

}
