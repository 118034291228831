import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { SubmissionError } from 'redux-form';
import { Link } from 'react-router-dom';
import get from 'lodash.get';

import Page from '../../components/page';
import GetSmsTokenForm from '../../components/getsmstokenform';
import {
  fetchSMSToken,
  fetchUserInfo,
  storeSessionType,
  storeUserInfo,
} from '../../actions';
import { redirectTo } from '../../actions/utils';
import { enterTokenPath, frontpagePath, logoutPath } from '../../routeConstants';
import LinkList from '../../components/linklist';

import pofhConfig from '../../pofhConfig';
const {
  SMSTokenRequiresId,
  stripLastAtSignAndRestFromUsername,
  forgottenUsernameLinkOnNewUserPage,
} = pofhConfig.appSettings;

const fetchSmsTokenError = (errorAction) => {
  const { type } = errorAction.error;
  const reason = get(errorAction, 'error.details.reason', null);

  let errorI18nTag = 'serverErrors.serverDown';

  switch (type) {
    case 'not-found-error':
      errorI18nTag = 'serverErrors.requestSmsToken';
      break;
    case 'rate-limit-error':
      errorI18nTag = 'serverErrors.rateLimit';
      break;
    case 'invalid-mobile-number':
      switch (reason) {
        case 'invalid-region':
          errorI18nTag = 'serverErrors.invalidPhoneNrRegion';
          break;
        default:
          errorI18nTag = 'serverErrors.invalidPhoneNr';
      }
      break;
    case 'service-unavailable':
      switch (reason) {
        case 'inactive-account':
          errorI18nTag = 'serverErrors.inactiveAccount';
          break;
        case 'quarantined':
          errorI18nTag = 'serverErrors.inactiveAccount';
          break;
        case 'reserved-account':
          errorI18nTag = 'serverErrors.reservedAccountGeneric';
          break;
        case 'reserved-by-self':
          errorI18nTag = 'serverErrors.reservedAccountSelf';
          break;
        case 'cannot-send-sms':
          errorI18nTag = 'serverErrors.serverDown';
          break;
        default:
          errorI18nTag = 'serverErrors.reservedAccountGeneric';
      }
      break;
    default:
      errorI18nTag = 'serverErrors.unknownType';
  }
  const _error = I18n.t(errorI18nTag);
  throw new SubmissionError({ _error });
};

class GetSMSToken extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.cancel = this.cancel.bind(this);
  }
  cancel() {
    if (this.props.username && this.props.identifier) {
      redirectTo(logoutPath, {
        logoutHeader: 'general.logged_out',
        logoutMessageTag: 'general.now_logged_out',
      });
    } else redirectTo(frontpagePath);
  }
  onSubmit(formValues) {
    const payload = {};
    payload.username = formValues.username;
    payload.mobile = formValues.countrycode + formValues.mobile;

    if (stripLastAtSignAndRestFromUsername) {
      payload.username = payload.username.replace(/@[^@]*$/, '');
    }

    if (SMSTokenRequiresId) {
      payload.identifier = formValues.identifier;
      if (formValues.identifier.length === 11) {
        payload.identifier_type = 'norwegianNationalId';
      } else {
        payload.identifier_type = 'studentNumber';
        // Strip leading zeroes
        payload.identifier = payload.identifier.replace(/^0+/, '');
      }
    }

    return this.props.fetchSMSToken(payload).then(() => {
      this.props.storeSessionType('pofh');
      redirectTo(enterTokenPath);
    }, fetchSmsTokenError);
  }

  render() {
    return (
      <Page
        header={<Translate value={this.props.header} />}
        infoText={I18n.t(this.props.infoText)}
      >
        {this.props.location.pathname === '/newUser' &&
          forgottenUsernameLinkOnNewUserPage && (
            <LinkList>
              <Link to="/forgottenUsername">
                <Translate value="general.forgotten_username" />
              </Link>
            </LinkList>
          )}
        <GetSmsTokenForm onSubmit={this.onSubmit} cancel={this.cancel} />
      </Page>
    );
  }
}

GetSMSToken.propTypes = {
  locale: PropTypes.string,
  fetchSMSToken: PropTypes.func.isRequired,
  fetchUserInfo: PropTypes.func.isRequired,
  storeSessionType: PropTypes.func.isRequired,
  storeUserInfo: PropTypes.func.isRequired,
  username: PropTypes.string,
  identifier: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
    username: state.session.username,
    identifier: state.session.identifier,
  };
}

export default connect(mapStateToProps, {
  fetchSMSToken,
  fetchUserInfo,
  storeSessionType,
  storeUserInfo,
})(GetSMSToken);
