import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';

import Page from '../../components/page';
import Button from '../../components/button';

import { storeUsername } from '../../actions';
import { getSmsTokenPath, frontpagePath, logoutPath } from '../../routeConstants';
import { redirectTo } from '../../actions/utils';

class ViewUserNames extends React.Component {
  constructor(props) {
    super(props);
    this.selectUsername = this.selectUsername.bind(this);
    this.logout = this.logout.bind(this);
  }
  UNSAFE_componentWillMount() {
    if (this.props.userNames === null) redirectTo(frontpagePath);
  }
  selectUsername(username) {
    this.props.storeUsername(username);
    redirectTo(getSmsTokenPath);
  }

  logout() {
    redirectTo(logoutPath, {
      logoutHeader: 'general.logged_out',
      logoutMessageTag: 'general.now_logged_out',
    });
  }

  render() {
    if (this.props.userNames === null) return null;
    const header =
      this.props.userNames.length === 1 ? (
        <Translate value="general.your_username" />
      ) : (
        <Translate value="general.your_usernames" />
      );
    const infoText =
      this.props.userNames.length === 1 ? (
        <Translate value="general.your_username_info" />
      ) : (
        <Translate value="general.your_usernames_info" />
      );
    return (
      <Page header={header} infoText={infoText}>
        <ul className="username-list">
          {this.props.userNames.map((username, index) => {
            return (
              <li key={index} className="username-list__list-item">
                {username}&nbsp;-
                <span
                  className="username-list__list-item__link"
                  onClick={this.selectUsername.bind(this, username)}
                >
                  <Translate value="general.change_password" />
                </span>
              </li>
            );
          })}
        </ul>
        <Button
          type="button"
          onClick={this.logout}
          text={<Translate value="general.finish" />}
        />
      </Page>
    );
  }
}

ViewUserNames.propTypes = {
  locale: PropTypes.string,
  userNames: PropTypes.arrayOf(PropTypes.string),
  storeUsername: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
    userNames: state.session.userNames,
  };
}

export default connect(mapStateToProps, {
  storeUsername,
})(ViewUserNames);
