import React from 'react';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { I18n, Translate } from 'react-redux-i18n';
import spinner from '../../static/spinner.gif';

import Button, { ButtonGroup } from '../../components/button';
import ButtonLink from 'components/buttonlink';
import { Popup, PopupHeader, PopupText } from '../../components/popup';
import { delOtpSecret, getOtpStatus } from '../../actions';
import { redirectTo } from '../../actions/utils';
import {
  otpOnPath,
  otpOffPath,
  logoutPath,
} from '../../routeConstants';
import { CheckmarkOn, CheckmarkOff } from '../../components/icons'
import '../../styles/components/toggleotpform.scss'

import pofhConfig from 'pofhConfig';

const { apiBaseUrl, oidc } = pofhConfig.apiSettings;

class ToggleOtpForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOtpOn: null,
      showOtpPopup: false
    }
    this.getOtpStatusDiv = this.getOtpStatusDiv.bind(this);
    this.getOtpButton = this.getOtpButton.bind(this);
    this.getOtpPopup = this.getOtpPopup.bind(this);
    this.showOtpPopup = this.showOtpPopup.bind(this);
    this.hideOtpPopup = this.hideOtpPopup.bind(this);
    this.handleTurnOnSubmit = this.handleTurnOnSubmit.bind(this);
    this.handleTurnOffSubmit = this.handleTurnOffSubmit.bind(this);
    this.handleError = this.handleError.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
  }
  showOtpPopup() {
    this.setState({ showOtpPopup: true })
  }
  hideOtpPopup() {
    this.setState({ showOtpPopup: false })
  }
  getOtpStatusDiv() {
    const isOn = this.state.isOtpOn;
    return (
      <div className={isOn ? 'otp-status-on' : 'otp-status-off'}>
        <Translate
          value={isOn ? 'general.otp_on' : 'general.otp_off'}
        />
        {isOn ? <CheckmarkOn /> : <CheckmarkOff />}
      </div>
    )
  }
  getOtpButton() {
    if (this.props.hasOidcLogin) {
      return (
        <Button type='button'
          text={I18n.t(this.state.isOtpOn ? 'general.otp_confirm_off'
            : 'general.otp_confirm_on')}
          onClick={this.state.isOtpOn ? this.showOtpPopup
            : this.handleTurnOnSubmit}
        />
      )
    }
    return (
      <ButtonLink
        href={apiBaseUrl + oidc.idporten.reqAuthEndpoint}
        text={I18n.t('general.log_in_ext')}
      />
    )
  }
  getOtpPopup() {
    return (
      <Popup>
        <PopupHeader>
          <Translate value='general.otp_popup_header' />
        </PopupHeader>
        <PopupText>
          <Translate value='general.otp_popup_text' />
        </PopupText>
        <ButtonGroup>
          <Button type='button'
            onClick={this.hideOtpPopup}
            text={I18n.t('general.cancel')}
            secondary
          />
          <Button type='submit'
            onClick={this.handleTurnOffSubmit}
            text={I18n.t('general.otp_confirm_off')}
          />
        </ButtonGroup>
      </Popup>
    )
  }
  handleTurnOnSubmit() {
    redirectTo(otpOnPath);
  }
  handleTurnOffSubmit() {
    return (this.props.delOtpSecret().then(
      () => redirectTo(otpOffPath),
      this.handleError
    ))
  }
  handleError(errorAction) {
    const _error = {};
    if (errorAction.error.type === 'unauthorized') {
      redirectTo(logoutPath, {
        logoutHeader: 'general.logged_out',
        logoutMessageTag: 'general.warning_oidc_session_expired',
      });
    } else if (errorAction.error.type === 'missing-session') {
      _error._error = <Translate value="serverErrors.missingSession" />;
    } else if (errorAction.error.type === 'rate-limit-error') {
      _error._error = <Translate value="serverErrors.rateLimit" />;
    } else {
      _error._error = <Translate value="serverErrors.serverDown" />;
    }
    throw new SubmissionError(_error);
  }
  componentDidMount() {
    return (this.props.getOtpStatus().then(
      (success) => this.setState({ isOtpOn: success.response.has_secret }),
      this.handleError
    ))
  }
  render() {
    return (
      this.state.isOtpOn === null ? (
        <img className="spinner" src={spinner} alt="spinner" />
      ) : (
        <div>
          <h2 className='otp-header'>
            {I18n.t('general.otp_header')}
          </h2>
          <div className='otp-change-status-info'>
            {I18n.t('general.otp_change_status_info')}
          </div>
          <div className='otp-body'>
            {this.getOtpStatusDiv()}
            {this.getOtpButton()}
          </div>
          {this.state.showOtpPopup && this.getOtpPopup()}
        </div>
      )
    )
  }
}

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
  };
}

export default connect(mapStateToProps, {
  delOtpSecret,
  getOtpStatus
})(ToggleOtpForm);
