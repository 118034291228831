import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";
import { parse } from "query-string";

import {
  setLanguage,
  mountApp,
  logout,
  clearLogoutMessage,
} from "../../actions";
import logoutClock from "../../static/logout-clock.svg";
import { callSiteWrapperFunc } from "../../themeUtils";
import { Popup, PopupHeader, PopupText } from "../../components/popup";
import Button, { ButtonGroup } from "../../components/button";

import { history } from "../../configureStore"

class App extends React.Component {
  constructor(props) {
    super(props);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    callSiteWrapperFunc("attachToggleLanguageListener", [
      this.handleLanguageChange,
    ]);
    this.props.mountApp();

    if (history.location && history.location.search) {
      const parsedQuery = parse(history.location.search);
      if (
        parsedQuery.lang &&
        parsedQuery.lang !== this.props.locale
      ) {
          this.props.setLanguage(parsedQuery.lang);
      }
    }
  }

  componentWillUnmount() {
    callSiteWrapperFunc("detachToggleLanguageListener", [
      this.handleLanguageChange,
    ]);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.locale !== nextProps.locale) {
      callSiteWrapperFunc("updateLanguage", [nextProps.locale]);
    }
  }

  handleLanguageChange() {
    if (this.props.locale === "nb") {
      this.props.setLanguage("en");
    } else {
      this.props.setLanguage("nb");
    }
  }

  render() {
    const { logoutHeader, logoutMessageTag, logoutMessageTagVars } = this.props;
    return (
      <div className="app-root">
        {this.props.logoutMessageTag && (
          <Popup>
            <PopupHeader>
              <Translate value={logoutHeader} />
            </PopupHeader>
            <PopupText>
              <Translate value={logoutMessageTag} {...logoutMessageTagVars} />
            </PopupText>
            {logoutMessageTag === "general.password_delay_notice" && (
              <div className="popup-imageContainer">
                <img src={logoutClock} alt="" />
              </div>
            )}
            <ButtonGroup>
              <Button text="OK" onClick={this.props.clearLogoutMessage} />
            </ButtonGroup>
          </Popup>
        )}
        {this.props.children}
      </div>
    );
  }
}

App.propTypes = {
  locale: PropTypes.string.isRequired,
  setLanguage: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
    logoutHeader: state.session.logoutHeader,
    logoutMessageTag: state.session.logoutMessageTag,
    logoutMessageTagVars: state.session.logoutMessageTagVars,
  };
}

export default connect(mapStateToProps, {
  setLanguage,
  mountApp,
  logout,
  clearLogoutMessage,
})(App);
