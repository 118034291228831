import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Field, reduxForm } from 'redux-form';
import Input from '../input';
import PasswordInput from '../passwordinput';
import Button from '../button';
import ReCaptcha from '../recaptcha';

import pofhConfig from '../../pofhConfig';
import Warning from '../warning';
const { reCaptchaEnabled, lowerCaseUsernameOnly } = pofhConfig.appSettings;

const lower = value => value && value.toLowerCase();
const noop = value => value;
const usernameNormalizer = lowerCaseUsernameOnly ? lower : noop;

const validate = (fields) => {
  const error = {};
  if (!fields.username)
    error.username = I18n.t('formErrors.requiredField');
  if (!fields.password)
    error.password = I18n.t('formErrors.requiredField');
  if (!fields['g-recaptcha-response'])
    error['g-recaptcha-response'] = I18n.t('formErrors.requiredField');
  return error;
};

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
    this.state = { showPassword: false };
  }
  togglePasswordVisibility() {
    this.setState({ showPassword: !this.state.showPassword })
  }
  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        {this.props.error &&
          <Warning text={this.props.error} color="red" formWarning={true} />
        }
        <Field name="username"
          type="text"
          label={I18n.t('general.username')}
          component={Input}
          autofocus
          hideError={true}
          normalize={usernameNormalizer}
        />
        <Field name="password"
          label={I18n.t('general.password')}
          locale={this.props.locale}
          visible={this.state.showPassword}
          toggleText={!this.state.showPassword ?
            I18n.t('general.show_password') :
            I18n.t('general.hide_password')}
          toggleVisibility={this.togglePasswordVisibility}
          component={PasswordInput}
          hideError={true}
        />
        {reCaptchaEnabled &&
          <Field name="g-recaptcha-response"
            component={ReCaptcha}
          />
        }
        <Button type='submit'
          text={I18n.t('general.log_in')}
          fetching={this.props.submitting}
          disabled={this.props.pristine || !this.props.valid}
        />
      </form>
    )
  }
}
LoginForm = reduxForm({ form: 'loginForm', validate })(
  LoginForm
)

LoginForm.propTypes = {
  locale: PropTypes.string
};

export default connect((state) => ({
  locale: state.i18n.locale
}))(LoginForm);
